<div [hidden]="dataSource.data.length == 0" class="fmsf_mat_override">
  <mat-card>
    <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-card-title role="heading" aria-level="1">Model Run Details</mat-card-title>
        <!--        <mat-card-subtitle role="heading" aria-level="2"></mat-card-subtitle>-->
      </div>
    </mat-card-header>
    <mat-card-content class="mat-elevation-z8">
      <div fxLayout="column" fxLayoutGap="16px">
        <table mat-table matSort [dataSource]="dataSource" class="search-table" (matSortChange)="util.announceSortChange($event)"
               matSortActive="runId" matSortDirection="desc">
          <ng-container matColumnDef="runId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Run ID"> Run ID </th>
            <td mat-cell *matCellDef="let detail"> {{detail.runId}} </td>
          </ng-container>
          <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Owner"> Owner </th>
            <td mat-cell *matCellDef="let detail"> {{detail.owner}} </td>
          </ng-container>
          <ng-container matColumnDef="modelType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Model Type"> Model Type </th>
            <td mat-cell *matCellDef="let detail"> {{detail.modelType}} </td>
          </ng-container>
          <ng-container matColumnDef="runStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Run Status"> Run Status </th>
            <td mat-cell *matCellDef="let detail"> {{detail.runStatus}} </td>
          </ng-container>
<!--          <ng-container matColumnDef="statusMsg">-->
<!--            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status Message"> Status Msg </th>-->
<!--            <td mat-cell *matCellDef="let detail"> {{detail.statusMsg}} </td>-->
<!--          </ng-container>-->
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Created"> Created </th>
            <td mat-cell *matCellDef="let detail"> {{detail.created | date:'short'}} </td>
          </ng-container>
<!--          <ng-container matColumnDef="started">-->
<!--            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Started"> Started </th>-->
<!--            <td mat-cell *matCellDef="let detail"> {{detail.started | date:'short'}} </td>-->
<!--          </ng-container>-->
          <ng-container matColumnDef="completed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Completed"> Completed </th>
            <td mat-cell *matCellDef="let detail"> {{detail.completed | date:'short'}} </td>
          </ng-container>
<!--          <ng-container matColumnDef="landscapeId">-->
<!--            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Landscape ID"> Landscape ID </th>-->
<!--            <td mat-cell *matCellDef="let detail"> {{detail.landscapeId}} </td>-->
<!--          </ng-container>-->



          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">No data or nothing that matches the filter "{{input.value}}"</td>
          </tr>
        </table>

        <div fxLayout width="100%">
          <div fxLayoutAlign="start" fxFlex="20">
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Filter</mat-label>
              <input matInput type="text" (keyup)="applyFilter($event)" #input>
            </mat-form-field>
          </div>

          <div fxLayoutAlign="end" fxFlex="80">
            <mat-paginator appPaginationSave [lsKey]="'model_run_status_page_size'" [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons aria-label="Select page of notifications"></mat-paginator>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
