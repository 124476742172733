<div>
  <form #f="ngForm" (ngSubmit)="genKey(f.value)">
    <mat-card>
      <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-card-title role="heading" aria-level="1">API Key</mat-card-title>
          <mat-card-subtitle role="heading" aria-level="2">Current key for API calls. Select button to generate a new key.</mat-card-subtitle>
        </div>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <div fxFlex="75%" fxLayout="column">
          <span>Username: {{stateService.user.user_id}}</span>
          <span>API Key: "{{key}}"</span>
          <span>Copy content between double quotes (")</span>
        </div>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="center">
        <button mat-raised-button type="button" color="primary" *ngIf="util.haveClipboard" (click)="onCopy()">Copy</button>
<!--        <button mat-raised-button type="button" color="primary" *ngIf="util.haveClipboard" (click)="onCopy()" appAutoFocus>Copy</button>-->
        <button mat-raised-button type="submit" color="warn">Generate New Key</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>




