<div fxFill>
  <mat-card>
    <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-card-title role="heading" aria-level="1">Release Notes</mat-card-title>
      </div>
    </mat-card-header>
    <mat-card-actions fxLayout="row" fxLayoutAlign="center">
      <button *ngIf="!sideBarService.enabled" mat-raised-button color="primary" (click)="onHome()">Home</button>
    </mat-card-actions>
  </mat-card>

  <mat-card>
    <mat-card-content fxLayout="row" fxLayoutAlign="center" style="height: 100%">
      <div fxFlex="75%" fxLayout="column">
        <span>Version: 240223</span>
        <ul>
          <li>FMSF Account UI</li>
          <ul>
            <li>Updated User Guide</li>
            <li>Vulnerability Disclosure Policy link added to the Home page</li>
          </ul>
        </ul>
        <ul>
          <li>FMSF API</li>
          <ul>
            <li>FSPR defect where perimeters could not be output is fixed</li>
          </ul>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content fxLayout="row" fxLayoutAlign="center" style="height: 100%">
      <div fxFlex="75%" fxLayout="column">
        <span>Version: 231213</span>
        <ul>
          <li>FMSF Account UI</li>
          <ul>
            <li>No changes</li>
          </ul>
        </ul>
        <ul>
          <li>FMSF API</li>
          <ul>
            <li>Modeling library updated for all models (9/17/2023)</li>
          </ul>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content fxLayout="row" fxLayoutAlign="center" style="height: 100%">
      <div fxFlex="75%" fxLayout="column">
        <span>Version: 230911</span>
        <ul>
          <li>FMSF Account UI</li>
          <ul>
            <li>Disabling of accounts to meet security requirements. Accounts will be disabled due to lack of use for more than 90 days (Account UI or RestAPI). Accounts will be disabled if the Rules of Behavior have not been accepted annually</li>
            <li>Changed Contact Us email to: OCIO-EAS-FMSF-Admins@usda.gov</li>
            <li>Updated version of the User Guide</li>
          </ul>
        </ul>
        <ul>
          <li>FMSF API</li>
          <ul>
            <li>No changes</li>
          </ul>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content fxLayout="row" fxLayoutAlign="center" style="height: 100%">
      <div fxFlex="75%" fxLayout="column">
        <span>Version: 230626</span>
        <ul>
          <li>FMSF Account UI</li>
          <ul>
            <li>Added Limiting Shapefile ID field to /model/run in Swagger</li>
            <li>Updated version of the User Guide</li>
          </ul>
        </ul>
        <ul>
          <li>FMSF API</li>
          <ul>
            <li>Enable Limiting Shapefile input to allow users to limit the spatial extent of calculations for FOFM model runs</li>
            <li>Internal references for FOFM updated from SpatialFOFEM Combustion and Emissions to SpatialFOFEM Consumption and Emissions to reflect the proper model name</li>
            <li>Internal references for FLAM updated from Basic to FlamMap to reflect the proper model name</li>
          </ul>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content fxLayout="row" fxLayoutAlign="center" style="height: 100%">
      <div fxFlex="75%" fxLayout="column">
        <span>Version: 230530</span>
        <ul>
          <li>FMSF Account UI</li>
          <ul>
            <li>Updated version of the User Guide</li>
          </ul>
          <li>FMSF API</li>
          <ul>
            <li>Revised and removed some model run input parameter defaults</li>
            <li>Enabled FSPROCONTAINSUMMARY output switch to allow users to retrieve the generated Contain Summary from FSPro model runs</li>
            <li>Removed deprecated API endpoints</li>
          </ul>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content fxLayout="row" fxLayoutAlign="center" style="height: 100%">
      <div fxFlex="75%" fxLayout="column">
        <span>Version: 230323</span>
        <ul>
          <li>FMSF Account UI</li>
          <ul>
            <li>Updated Version of the User Guide</li>
          </ul>
          <li>FMSF API</li>
          <ul>
            <li>Updated FSPR input limits for cell limits, Duration, NumFires, NumWindDirs, NumWindSpeeds, NumERCClasses. See the User Guide for details</li>
            <li>Updated SFTM input limits for FOFEM_FlameLength. See the User Guide for details</li>
            <li>Added ability to download the landscape/GeoTIFF for FOFM and SFTM models using the model/modelLandscape call</li>
          </ul>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content fxLayout="row" fxLayoutAlign="center" style="height: 100%">
      <div fxFlex="75%" fxLayout="column">
        <span>Version: 230104</span>
        <ul>
          <li>FMSF Account UI</li>
          <ul>
            <li>New input file calls added to Swagger</li>
            <li>Updated version of the User Guide</li>
          </ul>
          <li>FMSF API</li>
          <ul>
            <li>Endpoint added to download user uploaded input file (/model/userInput/&#123;Run ID&#125;)</li>
            <li>Endpoint added to download input file used to run a model (/model/fmsfInput/&#123;Run ID&#125;)</li>
            <li>RNDG known defect where using GeoTIFFs with >12 bands caused failures has been fixed</li>
            <li>FARS known WindNinja error causing model run failures has been fixed. The FMSF now adjusts the GRIDDED_WINDS_RESOLUTION to insure fewer than 1 million pixels to enable the model to run</li>
            <li>Performance optimization made to small model resources</li>
          </ul>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content fxLayout="row" fxLayoutAlign="center" style="height: 100%">
      <div fxFlex="75%" fxLayout="column">
        <span>Version: 221129</span>
        <ul>
          <li>FMSF Account UI</li>
          <ul>
            <li>Pending Model Requests added to the Dashboard</li>
            <li>Model Run Details added to the Dashboard</li>
            <ul>
              <li>Displays select metadata for runs over the prior 14 days</li>
            </ul>
            <li>Swagger only available to logged in Users</li>
            <li>Updated version of the User Guide</li>
          </ul>
          <li>FMSF API</li>
          <ul>
            <li>Modeling library updated for all models (7/22/22)</li>
            <li>RNDG increased ignitions to 500,000</li>
            <li>FOFM known defect where the FCCS band was any location other than 1 resulted in failures has been fixed</li>
            <li>MTT_ and FARS ability to pass multipoint ignitions</li>
          </ul>
        </ul>

      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content fxLayout="row" fxLayoutAlign="center" style="height: 100%">
      <div fxFlex="75%" fxLayout="column">
        <span>Version: 220815</span>
        <ul>
          <li>Initial Release!</li>
          <li>FMSF API access</li>
          <li>Swagger access</li>
          <li>User guide</li>
          <li>FMSF User Account Interface with:</li>
          <ul>
            <li>Two factor authentication via FAMAuth</li>
            <li>Viewing and requesting model access</li>
            <li>View, generate, and copy API key</li>
          </ul>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>

</div>

