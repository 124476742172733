import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UtilService} from "../../services/util.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  constructor(private util : UtilService) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.util.checkROB()) {
      this.util.displayROB();
    }

  }

}
