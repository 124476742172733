<!--<button mat-raised-button color="primary" (click)="onEditUser()">Edit User</button>-->
<!--<br>-->
<div class="fmsf_mat_override">
  <form #f="ngForm" (ngSubmit)="onSearch(f.value)" role="search">
    <mat-card>
      <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-card-title role="heading" aria-level="1">User Search</mat-card-title>
<!--          <mat-card-subtitle></mat-card-subtitle>-->
        </div>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <div fxFlex="90" fxLayout="row" fxLayoutGap="16px">
          <div fxLayout="column" fxFlex="50">
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput name="email" ngModel type="text" [(ngModel)]="stateService.search_email">
<!--              <input matInput name="email" ngModel type="text" [(ngModel)]="stateService.search_email" appAutoFocus>-->
              <button mat-button *ngIf="stateService.search_email" matSuffix mat-icon-button aria-label="Clear"
                      (click)="stateService.search_email=undefined">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Username</mat-label>
              <input matInput name="user_id" ngModel type="text" maxlength="32" [(ngModel)]="stateService.search_user">
              <button mat-button *ngIf="stateService.search_user" matSuffix mat-icon-button aria-label="Clear"
                      (click)="stateService.search_user=undefined">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>First Name</mat-label>
              <input matInput name="first_name" ngModel type="text" maxlength="32" [(ngModel)]="stateService.search_firstname">
              <button mat-button *ngIf="stateService.search_firstname" matSuffix mat-icon-button aria-label="Clear"
                      (click)="stateService.search_firstname=undefined">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Last Name</mat-label>
              <input matInput name="last_name" ngModel type="text" maxlength="32" [(ngModel)]="stateService.search_lastname">
              <button mat-button *ngIf="stateService.search_lastname" matSuffix mat-icon-button aria-label="Clear"
                      (click)="stateService.search_lastname=undefined">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="50">
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Model</mat-label>
              <mat-select name="model_type" ngModel [(ngModel)]="stateService.search_model">
                <mat-option *ngFor="let model of modelTypes" [value]="model.id">
                  {{model.shortName}} - {{model.commonName}}
                </mat-option>
              </mat-select>
              <button mat-button *ngIf="stateService.search_model" matSuffix mat-icon-button aria-label="Clear"
                      (click)="stateService.search_model=undefined; $event.stopPropagation()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Organization</mat-label>
              <mat-select name="org" ngModel [(ngModel)]="stateService.search_org">
                <mat-option *ngFor="let org of orgList" [value]="org.org_name">
                  {{org.org_name}}
                </mat-option>
              </mat-select>
              <button mat-button *ngIf="stateService.search_org" matSuffix mat-icon-button aria-label="Clear"
                      (click)="stateService.search_org=undefined; $event.stopPropagation()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>User Role</mat-label>
              <mat-select name="role_id" ngModel [(ngModel)]="stateService.search_role">
                <mat-option *ngFor="let key of util.roleKeys" [value]="key">
                  {{util.role[$any(key)]}}
                </mat-option>
              </mat-select>
              <button mat-button *ngIf="stateService.search_role" matSuffix mat-icon-button aria-label="Clear"
                      (click)="stateService.search_role=undefined; $event.stopPropagation()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>User Status</mat-label>
              <mat-select name="account_status_id" ngModel [(ngModel)]="stateService.search_status">
                <mat-option *ngFor="let key of util.statusKeys" [value]="key">
                  {{util.status[$any(key)]}}
                </mat-option>
              </mat-select>
              <button mat-button *ngIf="stateService.search_status" matSuffix mat-icon-button aria-label="Clear"
                      (click)="stateService.search_status=undefined; $event.stopPropagation()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="center" fxLayoutGap="16px">
        <div fxFlex="50">
          <button mat-raised-button type="button" color="accent" (click)="onExport()" [disabled]="!dataSource.data.length">Export</button>
          <div fxFlex=""></div>
          <button mat-raised-button type="submit" color="primary" [disabled]="searching">Search</button>
        </div>
        <div fxFlex="50">
          <button mat-raised-button type="reset" color="accent">Clear</button>
          <div fxFlex=""></div>
          <button mat-raised-button type="button" color="warn" (click)="onNewUser()">New User</button>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>

  <mat-card>
    <mat-card-content class="mat-elevation-z8" fxLayout="column" fxLayoutGap="16px">
      <table mat-table matSort [dataSource]="dataSource" class="search-table" (matSortChange)="util.announceSortChange($event)">
        <ng-container matColumnDef="user_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by User"> User </th>
          <td mat-cell *matCellDef="let user"> {{user.user_id}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name"> Name </th>
          <td mat-cell *matCellDef="let user"> {{user.name}} </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email"> Email </th>
          <td mat-cell *matCellDef="let user"> {{user.email}} </td>
        </ng-container>
        <ng-container matColumnDef="org">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Org"> Org </th>
          <td mat-cell *matCellDef="let user"> {{user.org}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status"> Status </th>
          <td mat-cell *matCellDef="let user"> {{user.status}} </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Role"> Role </th>
          <td mat-cell *matCellDef="let user"> {{user.role}} </td>
        </ng-container>
        <ng-container matColumnDef="models">
          <th mat-header-cell *matHeaderCellDef> Models </th>
          <td mat-cell *matCellDef="let user"> {{user.models}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onEditUser(row)" (keyup)="onKeypress($event, row)" tabindex="0"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No data or nothing that matches the filter "{{input.value}}"</td>
        </tr>
      </table>

      <div fxLayout width="100%">
        <div fxLayoutAlign="start" fxFlex="20">
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Filter</mat-label>
            <input matInput type="text" (keyup)="applyFilter($event)" #input>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="end" fxFlex="80">
          <mat-paginator appPaginationSave [lsKey]="'search_page_size'" [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>





