<div class="fmsf_mat_override">
  <form #f="ngForm" (ngSubmit)="onSearch(f.value)" role="search">
    <mat-card>
      <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-card-title role="heading" aria-level="1">Model Run Details Search</mat-card-title>
          <!--          <mat-card-subtitle></mat-card-subtitle>-->
        </div>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <div fxFlex="90" fxLayout="row" fxLayoutGap="16px">
          <div fxLayout="column" fxFlex="50">
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Username</mat-label>
              <input matInput name="owner" ngModel type="text" maxlength="32" [(ngModel)]="stateService.mrd_search_user">
              <button mat-button *ngIf="stateService.mrd_search_user" matSuffix mat-icon-button aria-label="Clear"
                      (click)="stateService.mrd_search_user=undefined">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Model Type</mat-label>
              <mat-select name="modelType" ngModel [(ngModel)]="stateService.mrd_search_model">
                <mat-option *ngFor="let model of modelTypes" [value]="model.id">
                  {{model.id}} - {{model.shortName}}
                </mat-option>
              </mat-select>
              <button mat-button *ngIf="stateService.mrd_search_model" matSuffix mat-icon-button aria-label="Clear"
                      (click)="stateService.mrd_search_model=undefined; $event.stopPropagation()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Run Status</mat-label>
              <mat-select name="runStatus" ngModel [(ngModel)]="stateService.mrd_search_status">
                <mat-option *ngFor="let key of modelRunStatus" [value]="key">
                  {{key}}
                </mat-option>
              </mat-select>
              <button mat-button *ngIf="stateService.mrd_search_status" matSuffix mat-icon-button aria-label="Clear"
                      (click)="stateService.mrd_search_status=undefined; $event.stopPropagation()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="50">
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Created Start</mat-label>
              <input #createdStart="ngModel" matInput [matDatepicker]="createdStartPicker" name="createdStart" ngModel [(ngModel)]="stateService.mrd_search_createdStart">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <div matSuffix>
                <button mat-button *ngIf="stateService.mrd_search_createdStart" mat-icon-button aria-label="Clear"
                        (click)="stateService.mrd_search_createdStart=undefined; $event.stopPropagation()">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-datepicker-toggle [for]="createdStartPicker"></mat-datepicker-toggle>
              </div>
              <mat-datepicker #createdStartPicker></mat-datepicker>
            </mat-form-field>
            <div class="color_warn" *ngIf="createdStart.value && createdEnd.value && (createdStart.value >= createdEnd.value)">
              Created Start date must be before Created End
            </div>

            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Created End</mat-label>
              <input #createdEnd="ngModel" matInput [matDatepicker]="createdEndPicker" name="createdEnd" ngModel [(ngModel)]="stateService.mrd_search_createdEnd">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <div matSuffix>
                <button mat-button *ngIf="stateService.mrd_search_createdEnd" mat-icon-button aria-label="Clear"
                        (click)="stateService.mrd_search_createdEnd=undefined; $event.stopPropagation()">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-datepicker-toggle [for]="createdEndPicker"></mat-datepicker-toggle>
              </div>
              <mat-datepicker #createdEndPicker></mat-datepicker>
            </mat-form-field>
            <div class="color_warn" *ngIf="createdStart.value && createdEnd.value && (createdEnd.value <= createdStart.value)">
              Created End date must be after Created Start
            </div>

          </div>
        </div>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="center" fxLayoutGap="16px">
        <div fxFlex="50">
          <button mat-raised-button type="button" color="accent" (click)="onExport()" [disabled]="!dataSource.data.length">Export</button>
          <div fxFlex=""></div>
          <button mat-raised-button type="submit" color="primary" [disabled]="searching">Search</button>
        </div>
        <div fxFlex="50">
          <button mat-raised-button type="reset" color="accent">Clear</button>
          <div fxFlex=""></div>
<!--          <button mat-raised-button type="button" color="warn" (click)="onNewUser()">New User</button>-->
        </div>
      </mat-card-actions>
    </mat-card>
  </form>

  <mat-card>
    <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-card-title role="heading" aria-level="1">Model Run Details</mat-card-title>
        <!--        <mat-card-subtitle role="heading" aria-level="2"></mat-card-subtitle>-->
      </div>
    </mat-card-header>
    <mat-card-content class="mat-elevation-z8">
      <div fxLayout="column" fxLayoutGap="16px">
        <table mat-table matSort [dataSource]="dataSource" class="search-table" (matSortChange)="util.announceSortChange($event)"
               matSortActive="runId" matSortDirection="desc">
          <ng-container matColumnDef="runId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Run ID"> Run ID </th>
            <td mat-cell *matCellDef="let detail"> {{detail.runId}} </td>
          </ng-container>
          <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Owner"> Owner </th>
            <td mat-cell *matCellDef="let detail"> {{detail.owner}} </td>
          </ng-container>
          <ng-container matColumnDef="modelType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Model Type"> Model Type </th>
            <td mat-cell *matCellDef="let detail"> {{detail.modelType}} </td>
          </ng-container>
          <ng-container matColumnDef="runStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Run Status"> Run Status </th>
            <td mat-cell *matCellDef="let detail"> {{detail.runStatus}} </td>
          </ng-container>
          <ng-container matColumnDef="statusMsg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status Message"> Status Msg </th>
            <td mat-cell *matCellDef="let detail"> {{detail.statusMsg}} </td>
          </ng-container>
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Created"> Created </th>
            <td mat-cell *matCellDef="let detail"> {{detail.created | date:'short'}} </td>
          </ng-container>
          <ng-container matColumnDef="started">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Started"> Started </th>
            <td mat-cell *matCellDef="let detail"> {{detail.started | date:'short'}} </td>
          </ng-container>
          <ng-container matColumnDef="completed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Completed"> Completed </th>
            <td mat-cell *matCellDef="let detail"> {{detail.completed | date:'short'}} </td>
          </ng-container>
          <ng-container matColumnDef="landscapeId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Landscape ID"> Landscape ID </th>
            <td mat-cell *matCellDef="let detail"> {{detail.landscapeId}} </td>
          </ng-container>
          <ng-container matColumnDef="ignitionId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Ignition ID"> Ignition ID </th>
            <td mat-cell *matCellDef="let detail"> {{detail.ignitionId}} </td>
          </ng-container>
          <ng-container matColumnDef="barrierId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Barrier ID"> Barrier ID </th>
            <td mat-cell *matCellDef="let detail"> {{detail.barrierId}} </td>
          </ng-container>
          <ng-container matColumnDef="limitingShape">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Limiting Shape"> Limiting Shape </th>
            <td mat-cell *matCellDef="let detail"> {{detail.limitingShape}} </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">No data or nothing that matches the filter "{{input.value}}"</td>
          </tr>
        </table>

        <div fxLayout width="100%">
          <div fxLayoutAlign="start" fxFlex="20">
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Filter</mat-label>
              <input matInput type="text" (keyup)="applyFilter($event)" #input>
            </mat-form-field>
          </div>

          <div fxLayoutAlign="end" fxFlex="80">
            <mat-paginator appPaginationSave [lsKey]="'model_run_details_page_size'" [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons aria-label="Select page of notifications"></mat-paginator>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
