import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {Router} from "@angular/router";
import {HttpService} from "../../../services/http.service";
import {UtilService} from "../../../services/util.service";
import {HttpHeaders} from "@angular/common/http";
import {catchError, throwError} from "rxjs";
import {FMSFNotification} from "../../../models/notification";
import {StateService} from "../../../services/state.service";

@Component({
  selector: 'app-admin-notifications',
  templateUrl: './admin-notifications.component.html',
  styleUrls: ['./admin-notifications.component.scss']
})
export class AdminNotificationsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table!: MatTable<FMSFNotification>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['type', 'user', 'email', 'details', 'actions'];
  dataSource = new MatTableDataSource<FMSFNotification>();

  constructor(private router : Router,
              private httpService : HttpService,
              public util : UtilService) { }

  ngOnInit(): void {
    this.getNotifications();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getNotifications() {
    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers };
    return this.httpService.Get<FMSFNotification[]>('/ui_api/admin/notifications', options).pipe(
      catchError(err => {
        return throwError(err);
      })
    ).subscribe((response : FMSFNotification[]) => {
      console.log(response);
      this.dataSource.data = response;
    });
  }

  applyFilter(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onApprove(row: FMSFNotification) {
    // call updateNotification with accept
    this.updateNotification(row, "approve", "");
  }

  onDeny(row: FMSFNotification) {
    // pop up dialog to get reason, then call updateNotification
    // on cancel, do nothing
    // on success, get message and send to updateNotification
    this.util.displayDialogMessage("Denying Request",
      "Please enter the reason for the denial to add to the email to the user.",
      "Are you sure?", "Deny", true, "Cancel", "warn", "primary", true)
      .subscribe(res => {
        console.log(res);
        if (res) {
          this.updateNotification(row, "deny", res.response);
        }
      });
  }

  onEditUser(row: FMSFNotification) {
    this.router.navigate(['/admin/user-edit/' + row.userId] );
  }

  updateNotification(row: FMSFNotification, updateType: string, message: string) {

    this.util.updateNotification(row, updateType, message).subscribe(response => {
      console.log(response);
      row.saving = false;
      this.dataSource.data = response;
    });
  }
}
