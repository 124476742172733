import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import { Subscription } from 'rxjs';
import {ErrorMessageObj} from "../../models/error-messages";

@Component({
  selector: 'app-wildcard-error-page',
  templateUrl: './wildcard-error-page.component.html',
  styleUrls: ['./wildcard-error-page.component.scss'],
})
export class WildcardErrorPageComponent implements OnInit, OnDestroy {
  paramsSub$!: Subscription;
  errorMessage!: ErrorMessageObj;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    // @ts-ignore
    this.paramsSub$ = this.route.queryParams.subscribe((params : ErrorMessageObj) => {
      let statusCode = 404;
      let errorMessage = "We can't find what you're looking for";
      let detailMessage = 'Please use the navigation links on the left';
      if (params && params?.statusCode) {
        statusCode = !Number.isNaN(params.statusCode) ? +params.statusCode : 404;
      }
      if (params && params?.errorMessage) {
        errorMessage = params.errorMessage;
      }
      if (params && params?.detailMessage) {
        detailMessage = params.detailMessage;
      }
      this.errorMessage = {
        statusCode,
        errorMessage,
        detailMessage,
      };
    });
  }

  ngOnDestroy(): void {
    this.paramsSub$.unsubscribe();
  }
}
