
// 0 = user, 1 = admin
export enum UserRole {
  User = 0,
  Admin = 1
}

export enum SuperUser {
  False = 0,
  True = 1
}

export enum UserRoleString {
  'user',
  'admin'
}

// 0 = new reg, 1 = denied, 2 = active, 3 = disabled
export enum UserStatus {
  NewReg = 0,
  Denied = 1,
  Active = 2,
  Disabled = 3
}

export interface UserInfo {
  user_id : string,
  access_code : string,
  superuser : boolean
}

export interface User {
  user_id : string,
  email : string,
  first_name : string,
  last_name : string,
  org : string,
  account_status_id : UserStatus,
  role_id : UserRole,
  last_activity: string,
  rob_accept: string,
  userInfo: UserInfo
}

export interface UserPrivsId {
  userId : string,
  modelType : string,
}

export interface UserPrivs {
  id : UserPrivsId
  concurrentRuns : number
}

export interface SearchUserRet {
  user : User,
  userPrivs : UserPrivs[]
}

export interface UserPreference {
  id : string,
  outputGridType : string,
  mttMaxPathInterval : number,
  daysToKeep : number
}

export interface UserOutputDefaultId {
  userId : string,
  modelType : string,
  propertyId : number | string,
  gridded : boolean | string
}

export interface UserOutputDefault {
  id : UserOutputDefaultId,
  sortOrder : number | string
}
