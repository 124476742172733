import {Injectable} from '@angular/core';
import {NavMenu} from "../models/nav-menu";
import {MatDrawer} from "@angular/material/sidenav";
import {UserRole} from "../models/user";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private _nav: NavMenu[] = [];
  private drawer!: MatDrawer;
  private _enabled : boolean = false;
  autosize: boolean = false;

  constructor() { }

  setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
  }

  toggle(isOpen?: boolean): void {
    this.drawer.toggle(isOpen);
  }

  getNav() {
    return this._nav;
  }

  addNav(nav : NavMenu) {
    this._nav.push(nav);
  }

  setNav(nav : NavMenu[]) {
    this._nav = nav;
  }

  setNavByUser(userRole : UserRole) {
    if (userRole === UserRole.Admin) {
      this.setNav([
        {title:'Home/Landing',routeToLoad:'/'},
        {title:'Dashboard',routeToLoad:'/admin/dashboard'},
        {title:'API Key',routeToLoad:'/user/apikey'},
        {title:'Model Access',routeToLoad:'/user/accessreq'},
        {title:'View / Edit Users',routeToLoad:'/admin/user-search'},
        {title:'System Notification',routeToLoad:'/admin/sys-note'},
        {title:'Model Run Details',routeToLoad:'/admin/model-run-details'}
      ]);
    } else {
      this.setNav([
        {title:'Home/Landing',routeToLoad:'/'},
        {title:'Dashboard',routeToLoad:'/user/dashboard'},
        {title:'API Key',routeToLoad:'/user/apikey'},
        {title:'Model Access',routeToLoad:'/user/accessreq'}
      ]);
    }
  }

  get enabled(): boolean {
    return this._enabled;
  }

  set enabled(value: boolean) {
    this._enabled = value;
  }
}
