import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModelType, UtilService} from "../../services/util.service";
import {HttpService} from "../../services/http.service";
import {AccessRequest} from "../../models/access-request";
import {StateService} from "../../services/state.service";
import {UserPrivs, UserRole} from "../../models/user";
import {catchError, finalize, Subscription, throwError} from "rxjs";
import {NgForm} from "@angular/forms";
import {HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'app-access-request',
  templateUrl: './access-request.component.html',
  styleUrls: ['./access-request.component.scss']
})
export class AccessRequestComponent implements OnInit, OnDestroy {
  // modelTypes : ModelType[] = [];
  // modelSub!: Subscription;
  // accessRequest : AccessRequest[] = [{modelType : "", concurrentRuns : 1}];
  // userRole = UserRole;

  saving = false;

  modelTypes : ModelType[] = [];
  modelSub!: Subscription;
  userPrivs : UserPrivs[] = [];
  userPrivsSub!: Subscription;
  userPrivsOrig : UserPrivs[] = [];
  note : string = "";
  noteSub!: Subscription;


  UserRole: typeof UserRole = UserRole;

  @ViewChild('fModels', { read: NgForm }) fModels!: NgForm;

  constructor(private httpService : HttpService,
              private util : UtilService,
              public stateService : StateService) { }

  ngOnInit(): void {
    this.modelSub = this.util.modelTypeSub$.subscribe((mt: ModelType[]) => {
      this.modelTypes = mt;
    });
    this.userPrivsSub = this.util.userPrivsSub$.subscribe((up: UserPrivs[]) => {
      // make unique copy to not modify the state in other components
      // Using map because it's an array of objects and need more than a shallow copy to prevent modification.
      // However, this only copies the first level of object, concurrentRuns, the id field is still shallow
      this.userPrivsOrig = up.map(ar => ({...ar}) ) ;
      this.userPrivs = up.map(ar => ({...ar}) ) ;
    });
    this.noteSub = this.util.notificationSub$.subscribe((up: string) => {
      this.note = up;
    });
  }

  ngOnDestroy(): void {
    this.modelSub.unsubscribe();
    this.userPrivsSub.unsubscribe();
    this.noteSub.unsubscribe();
  }

  // onRequest() {
  //   console.log(this.accessRequest);
  // }

  // onAdd() {
  //   this.accessRequest.push({modelType : "", concurrentRuns : 1});
  // }

  // onRemove(i: number) {
  //   this.accessRequest.splice(i, 1);
  // }

  onSaveModels(value: any) {
    // not using passed in value, just here for testing. ngModel updating accessRequest
    this.saving = true;
    // console.log(value);
    this.userPrivs.forEach(req => {
      if (!req.concurrentRuns) req.concurrentRuns = 0;
    })
    // console.log(this.userPrivs);

    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers };
    return  this.httpService.Post<UserPrivs[]>('/ui_api/update_models/me', this.userPrivs, options).pipe(
      finalize(() => this.saving = false),
      catchError(err => {
        if (err.includes("SMTP")) {
          this.util.displayDialogMessage("Error: Email notification error",
            "The email notification for your request was not sent. Please use the Contact Us under Resources to request approval of models >1",
            err.substring(err.indexOf("SMTP"), err.indexOf("-")));
        }
        return throwError(err);
      })
    ).subscribe(response => {
      if (this.stateService.user.role_id == UserRole.Admin) {
        this.util.displayDialogMessage("Changes applied", "Auto approved admin request.");
      } else {
        this.util.displayDialogMessage("Request submitted", "Auto approved changes applied. Runs greater than 1 have been sent to an admin for approval. ");
      }

      this.fModels.form.markAsPristine();
      this.fModels.form.markAsUntouched();
      // this.util.init(false);
      this.util.getMyModelInfo();
    });
  }


  onReset() {
    this.userPrivs = this.userPrivsOrig;
    this.fModels.form.markAsPristine();
    this.fModels.form.markAsUntouched();
  }
}
