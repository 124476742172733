<mat-toolbar color="primary" role="banner">
  <mat-toolbar-row>
    <a class="skip-to-content-link bg_color_primary" [href]="util.skipLinkPath">Skip to main content</a>
    <button *ngIf="sideBarService.enabled" mat-icon-button (click)="onToggleDrawer()" aria-label="Menu Open/Close">
      <mat-icon>menu</mat-icon>
    </button>
    <img src="assets/img/fmsf_logo.png" width="70px" alt="FMSF logo">
<!--    <mat-icon>whatshot</mat-icon>-->
    <div> Fire Modeling Services Framework</div>

    <div fxFlex fxLayout fxLayoutAlign="flex-end">
      <button mat-button *ngIf="stateService.isLoggedIn" (click)="onLogout()" aria-label="Opens logout confirmation dialog">Logout</button>
      <button mat-button [matMenuTriggerFor]="helpMenu" #menuTrigger>Resources<mat-icon>more_vert</mat-icon></button>
<!--      <mat-icon>more_vert</mat-icon><mat-icon>expand_more</mat-icon>-->
      <mat-menu #helpMenu="matMenu">
        <button mat-menu-item (click)="onContactUs()" aria-label="Opens contact us dialog">Contact Us</button>
        <button mat-menu-item (click)="onReleaseNotes()" aria-label="Opens release notes page">Release Notes</button>
        <button mat-menu-item (click)="onUserSupport()"aria-label="Opens user support external page">User Guide</button>
        <button mat-menu-item *ngIf="stateService.isLoggedIn && stateService.userStatus === UserStatus.Active" (click)="onSwagger()"aria-label="Opens swagger external page">Swagger</button>
<!--        <button mat-menu-item (click)="onLogout()">Logout</button>-->
      </mat-menu>
    </div>

  </mat-toolbar-row>
</mat-toolbar>
