import {Directive, forwardRef} from '@angular/core';
import {HttpService} from "../services/http.service";
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from "@angular/forms";
import {delay, Observable, of, switchMap} from "rxjs";
import {map} from "rxjs/operators";

@Directive({
  selector: '[appEmailExist]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => EmailExistDirective),
      multi: true
    }
  ]
})

export class EmailExistDirective implements AsyncValidator {
  constructor(private httpService : HttpService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if(!control.value) {
      return of(null);
    }

    return of(control.value).pipe(delay(500),
      //Then instead return the observable of us actually checking the value.
      switchMap((value) =>
        this.httpService.Get<boolean>(`/ui_api/emailexist/${value}`).pipe(map(x => {
          return x ? {exists : true} : null;
        }))
      )
    );
  }
}
