import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {map, take} from 'rxjs/operators';
import {CustomModalComponent} from "../components/custom-modal/custom-modal.component";
import {ComponentType} from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class CustomModalService {

  constructor(private dialog: MatDialog) {
  }

  dialogRef!: MatDialogRef<CustomModalComponent>;

  public open(component: ComponentType<CustomModalComponent>, config: MatDialogConfig<any> | undefined) {
    this.dialogRef = this.dialog.open(component, config);
  }
  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed()
      .pipe(
        take(1),
        map(res => {
            return res;
          }
        ));
  }
}

