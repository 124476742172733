import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {HttpHeaders} from "@angular/common/http";
import {ModelRun} from "../../models/model-run";
import {catchError, Subscription, throwError} from "rxjs";
import {HttpService} from "../../services/http.service";
import {ModelType, UtilService} from "../../services/util.service";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {StateService} from "../../services/state.service";

@Component({
  selector: 'app-model-run-status',
  templateUrl: './model-run-status.component.html',
  styleUrls: ['./model-run-status.component.scss']
})
export class ModelRunStatusComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table!: MatTable<ModelRun>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['runId', 'modelType', 'runStatus', 'created', 'completed'];
  dataSource = new MatTableDataSource<ModelRun>();

  constructor(private httpService : HttpService,
              public util : UtilService,
              public stateService: StateService) { }

  ngOnInit(): void {
    this.getModelRuns();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getModelRuns() {
    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers };
    return this.httpService.Get<ModelRun[]>('/ui_api/model_runs', options).pipe(
      catchError(err => {
        return throwError(err);
      })
    ).subscribe((response : ModelRun[]) => {
      console.log(response);
      this.dataSource.data = response;
    });
  }

  applyFilter(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
