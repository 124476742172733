<mat-card>
  <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-card-title role="heading" aria-level="1">Current Models</mat-card-title>
      <mat-card-subtitle role="heading" aria-level="2">Model types with number of concurrent runs currently allowed</mat-card-subtitle>
    </div>
  </mat-card-header>
  <mat-card-content fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutGap="16px">
      <table>
        <thead>
        <tr>
          <th scope="col">Model Type</th>
          <th scope="col">Concurrent Runs</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let x of userPrivs; let i = index">
          <td>{{modelTypes[i].id}} - {{modelTypes[i].shortName}}</td>
          <td style="text-align: center">{{x.concurrentRuns}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="note.length > 0">Pending: {{note}}</div>
  </mat-card-content>
</mat-card>
