import { Injectable } from '@angular/core';
import {HttpService} from "./http.service";
import {SuperUser, User, UserPrivs, UserRole, UserStatus} from "../models/user";
import {BehaviorSubject, catchError, Observable, throwError} from "rxjs";
import {AriaLivePoliteness, LiveAnnouncer} from "@angular/cdk/a11y";
import {StateService} from "./state.service";

import * as XLSX from 'xlsx';
import {CustomModalComponent} from "../components/custom-modal/custom-modal.component";
import {CustomModalService} from "./custom-modal.service";
import {Sort} from "@angular/material/sort";
import {HttpHeaders} from "@angular/common/http";
import {FMSFNotification} from "../models/notification";


export interface OrgList {
  code : number,
  org_name : string
}

export interface ModelType {
  id : string,
  shortName : string,
  commonName : string,
  small : boolean,
  threadsafe : boolean
}

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private defaults = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890.'-)(*!~";
  private def_len = 40;
  private _roleKeys : string[] = [];
  private _statusKeys : string[] = []
  private _superUserKeys : string[] = []
  private _haveClipboard : boolean = false;
  private userPrivsDone = false;

  private orgListSub = new BehaviorSubject<OrgList[]>([]);
  orgListSub$ = this.orgListSub.asObservable();

  private modelTypeSub = new BehaviorSubject<ModelType[]>([]);
  modelTypeSub$ = this.modelTypeSub.asObservable();

  private userPrivsSub = new BehaviorSubject<UserPrivs[]>([]);
  userPrivsSub$ = this.userPrivsSub.asObservable();
  userPrivsResponse! : UserPrivs[];
  skipLinkPath! : string;

  private notificationSub = new BehaviorSubject<string>("");
  notificationSub$ = this.notificationSub.asObservable();


  constructor(private httpService: HttpService,
              private stateService : StateService,
              private modalService : CustomModalService,
              private liveAnnouncer: LiveAnnouncer) {
    this._roleKeys = Object.keys(UserRole).filter(f => !isNaN(Number(f)));
    this._statusKeys = Object.keys(UserStatus).filter(f => !isNaN(Number(f)));
    this._superUserKeys = Object.keys(SuperUser).filter(f => !isNaN(Number(f)));
  }

  randomKey(lengthOfCode?: number, possible?: string) {
    if (!lengthOfCode)
      lengthOfCode = this.def_len

    if (!possible) {
      possible = this.defaults;
    }

    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  init(initAll : boolean = true) {
    if (initAll) {
      this.userPrivsDone = false;

      this.httpService.Get<OrgList[]>(`/ui_api/orglist`).subscribe(response => {
        this.orgListSub.next(response);
      });
      this.httpService.Get<ModelType[]>(`/ui_api/modeltype`).subscribe(response => {
        this.modelTypeSub.next(response);
        this.setUserPrivs();
      });
    }

    this.getMyModelInfo();

  }

  getMyModelInfo() {
    // get users models for dashboard/Model access request
    this.httpService.Get<UserPrivs[]>('/ui_api/user_models/me').subscribe(response => {
      this.userPrivsResponse = response;
      this.setUserPrivs();
    });

    this.httpService.Get<FMSFNotification>('/ui_api/notification').subscribe(response => {
      if (response) {
        this.notificationSub.next(response.details);
        // this.notificationSub.next(response.updateModels.map(m => ' ' + m.id.modelType + '(' + m.concurrentRuns + ')').toString());
      } else {
        this.notificationSub.next("");
      }
    });
  }
//JSON.parse(response[1].details).map(m => ' ' + m.id.modelType + '(' + m.concurrentRuns + ')').toString()
  setUserPrivs() {
    if (this.userPrivsDone) {
      let accessRequest : UserPrivs[] = [];
      this.modelTypeSub.value.forEach(modelType => {
        let userModel = this.userPrivsResponse.find(userPriv => userPriv.id.modelType === modelType.id);
        accessRequest.push({id : {userId : this.stateService.user.user_id, modelType : modelType.id}, concurrentRuns : userModel?.concurrentRuns || 0})
      })
      this.userPrivsSub.next(accessRequest);
      // this.userPrivsSub.next(accessRequest.map(ar => ({...ar}) ) );
    }
    this.userPrivsDone = true;
  }

  get roleKeys(): string[] {
    return this._roleKeys;
  }

  get superUserKeys(): string[] {
    return this._superUserKeys;
  }

  get statusKeys(): string[] {
    return this._statusKeys;
  }

  get role()  {
    return UserRole;
  }

  get superuser()  {
    return SuperUser;
  }

  get status() {
    return UserStatus;
  }

  get haveClipboard(): boolean {
    return this._haveClipboard;
  }

  set haveClipboard(value: boolean) {
    this._haveClipboard = value;
  }

  copy(val : string) {
    navigator.clipboard.writeText(val);
  }

  getFileName(name?: string) {
    let timeSpan = new Date().toISOString();
    let sheetName = name || "ExportSheet";
    let fileName = `${sheetName}-${timeSpan}`;
    return {
      sheetName,
      fileName
    };
  }

  exportToExcel(arr: any[], name?: string) {
    let { sheetName, fileName } = this.getFileName(name);

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  displayDialogMessage(title: string, message: string,
                       subtitle: string|undefined = undefined,
                       confirmText: string = 'OK',
                       cancelBtn:boolean = false,
                       cancelText: string|undefined = 'Cancel',
                       confirmButtonColor: string|undefined = "primary",
                       cancelButtonColor: string|undefined = undefined,
                       getResponse: boolean = false,
                       ignoreESC: boolean = false,
                       dWidth: string = '480px',
                       dHeight: string = 'auto',
                       checkBox: boolean = false,
                       cbText: string =''): Observable<any> {
    const dataOptions = {
      title: title,
      subtitle: subtitle,
      message: message,
      notDisplayCancelBtn: !cancelBtn,
      confirmText: confirmText,
      cancelText : cancelText,
      confirmButtonColor: confirmButtonColor,
      cancelButtonColor: cancelButtonColor,
      getResponse: getResponse,
      ignoreESC: ignoreESC,
      checkBox: checkBox,
      cbText: cbText
    };
    const config = {
      data: dataOptions,
      disableClose: true,
      width: dWidth,
      height: dHeight
    };

    this.modalService.open(CustomModalComponent, config);
    return this.modalService.confirmed();
  }

  liveAnnounce(message : string, politeness?: AriaLivePoliteness): Promise<void> {
    return this.liveAnnouncer.announce(message, politeness);
  }

  announceSortChange(sortState: Sort): Promise<void> {
    if (sortState.direction) {
      return this.liveAnnounce("Sorted " + sortState.direction + "ending");
    } else {
      return this.liveAnnounce("Sorting cleared");
    }
  }

  updateNotification(row: FMSFNotification, updateType: string, message: string) : Observable<FMSFNotification[]> {
    row.saving = true;

    let body = {
      id: row.id,
      updateType: updateType,
      message: message
    }

    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers };
    return  this.httpService.Post<FMSFNotification[]>('/ui_api/admin/update_notification', body, options).pipe(
      catchError(err => {
        console.log(err);
        row.saving = false;
        if (err.includes("SMTP")) {
          this.displayDialogMessage("Error: Email notification error",
            "The email notification for your request was not sent. Try again and if it persists investigate possible AWS SES issue.");
        }
        return throwError(err);
      })
    );
    //   .subscribe(response => {
    //   console.log(response);
    //   row.saving = false;
    //   if (dataSource) {
    //     dataSource.data = response;
    //   }
    // });
  }

  checkROB() : boolean {
    let daysLeft = this.daysLeftROB(this.stateService.user.rob_accept);
    return (daysLeft<=30);
  }

  daysLeftROB(rob_accept:string): number{
    let robDate = new Date(rob_accept);
    let currentDate = new Date();

    let daysBetween = Math.floor((currentDate.getTime() - robDate.getTime()) / 1000 / 60 / 60 / 24);
    return 365-daysBetween;
  }

  displayROB() {
    const options = { 'responseType': 'text' as 'json' };
    this.httpService.GetRaw<any>('assets/html/rob.html', options)
      .subscribe(response => {
        // console.log(response)
        this.displayDialogMessage("Rules Of Behavior",
          response,
          "Please read and accept", "ACCEPT", false, "", "primary",
          undefined, false, true, "95%", "95%", true, "Check to accept the ROB")
          .subscribe(res => {
            if (res) {
              this.httpService.Get<User>('/ui_api/rob_accept')
                .subscribe(response => {
                  // console.log(response)
                  if (!!response) {
                    this.stateService.user = response;
                  }
                });
            }
          });
      });

    // this.displayDialogMessage("Rules Of Behavior",
    //   "<ol>                                                                                                                                                             " +
    //   "<li>PURPOSE. This notice is to ensure that users of EPDS abide by security requirements and procedures needed to protect EPDS and customer information resources." +
    //   " It is also intended to help raise security awareness and inform system users about security policies and procedures.</li>                                       " +
    //   "</ol>                                                                                                                                                            " +
    //   "<ol start='2'>                                                                                                                                                   " +
    //   "<li>National policy requirements regarding information systems are stated in the Federal Information Security Management Act (FISMA)                             " +
    //   "(Title III of the E-Government Act of 2002); the Computer Fraud and Abuse Act (18 U.S.C. Sec. 1030 [1993]); Office of Management and Budget (OMB)                " +
    //   "Circular No. A-123, Management Accountability and Control; and OMB Circular A-130, Management of Federal Information Resources.</li>                             " +
    //   "</ol>                                                                                                                                                            " +
    //   "<ol start='3'>                                                                                                                                                   " +
    //   "<li>This notice applies to EPDS system users.</li>                                                                                                               " +
    //   "</ol>                                                                                                                                                            " +
    //   "<ol start='4'>                                                                                                                                                   " +
    //   "<li>UNDERSTANDING AND AGREEMENTS. As a user of EPDS, I:</li>                                                                                                     " +
    //   "</ol>                                                                                                                                                            " +
    //   "<ul>                                                                                                                                                             " +
    //   "<li>Will use EPDS only for authorized purposes.</li>                                                                                                             " +
    //   "<li>Understand that information processed on this site may be monitored.</li>                                                                                    " +
    //   "<li>Will protect the EPDS system and all sensitive information contained in the system from unauthorized personnel.</li>                                         " +
    //   "<li>Will process only data that pertains to official business and is authorized to be processed on the system.                                                   " +
    //   "I will not retrieve information for someone who does not have authority to access the information.                                                               " +
    //   "I will not intentionally access, delete, or alter files, operating systems or programs.</li>                                                                     " +
    //   "<li>Acknowledge that I will receive user identifiers (user IDs) and passwords to authenticate my computer account. After receiving them, I will:</li>            " +
    //   "<li>Protect and not share or publicly post my password.</li>                                                                                                     " +
    //   "<li>Not knowingly permit or cause my username and password to be used by anyone other than myself or my authorized agent.</li>                                   " +
    //   "<li>Report to GAO if my password has been compromised.</li>                                                                                                      " +
    //   "<li>Be responsible for all activity that occurs on my individual account once my password has been used to log on.</li>                                          " +
    //   "<li>Ensure my password meets EPDS system complexity requirements.</li>                                                                                           " +
    //   "<li>Will use due care when adding a co-representative (when applicable).</li>                                                                                    " +
    //   "<li>Will use anti-virus software to scan all files for malicious software (e.g., viruses, worms, etc.) before uploading any documents into the EPDS system.</li> " +
    //   "<li>Will not try to disable or subvert EPDS security controls or monitoring mechanisms.</li>                                                                     " +
    //   "<li>Will ensure that the Web browser window is closed before navigating to other sites.</li>                                                                     " +
    //   "<li>Understand that any person who obtains information from a computer connected to the Internet                                                                 " +
    //   "in violation of her employer&rsquo;s computer-use restrictions is in violation of the Computer Fraud and Abuse Act.</li>                                         " +
    //   "</ul>                                                                                                                                                            " +
    //   "<ol start='5'>                                                                                                                                                   " +
    //   "<li>EFFECTIVE DATE. This agreement becomes effective by the date of your electronic acceptance of the terms of this notice.</li>                                 " +
    //   "</ol>                                                                                                                                                            " +
    //   "<p>ACCEPTANCE</p>                                                                                                                                                " +
    //   "<p>I have read and understand the above <em>Rules of Behavior</em>. By my electronic acceptance ,                                                                " +
    //   "I acknowledge and agree that my access to the EPDS systems is covered by, and subject to, such rules. Further,                                                   " +
    //   "I understand that unauthorized or inappropriate use of the EPDS system may result in the loss or limitation of my privilege and that                             " +
    //   "GAO retains the right, at its sole discretion, to terminate, cancel, or suspend my access rights to the EPDS system at any time, without notice.                 " +
    //   "I also understand that I could lose access to the system, as well prosecution, penalties or financial liability, depending on the severity of the misuse.</p>    ",
    //   "Please read and accept", "ACCEPT", false, "", "primary",
    //   undefined, false, true, "95%", "95%", true, "Check to accept the ROB")
    //   .subscribe(res => {
    //     if (res) {
    //       this.httpService.Get<User>('/ui_api/rob_accept')
    //         .subscribe(response => {
    //           console.log(response)
    //           if (!!response) {
    //             this.stateService.user = response;
    //           }
    //         });
    //     }
    //   });
  }

}
