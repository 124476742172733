import {AfterViewInit, Component, OnInit} from '@angular/core';
import {StateService} from "../../services/state.service";
import {UserStatus} from "../../models/user";
import {Router} from "@angular/router";

@Component({
  selector: 'app-unauthorized-user',
  templateUrl: './unauthorized-user.component.html',
  styleUrls: ['./unauthorized-user.component.scss']
})
export class UnauthorizedUserComponent implements OnInit, AfterViewInit {
  public type! : string;

  constructor(private stateService : StateService,
              private router : Router) { }

  ngOnInit(): void {
    if (this.stateService.isLoggedIn) {
      if (this.stateService.userStatus === UserStatus.NewReg) {
        this.type = "newreg"
      } else if (this.stateService.userStatus === UserStatus.Disabled) {
        this.type = "disabled"
      } else if (this.stateService.userStatus === UserStatus.Denied) {
        this.type = "denied"
      } else { // valid login and user, so attempted to access admin page
        this.type = "admin"
      }
    } else {
      this.type = "notloggedin"
    }
  }

  ngAfterViewInit(): void {
    // looks strange setting equal to self, but it's a getter and setter
    this.stateService.redirectUrl = this.stateService.redirectUrl;
  }

  goLanding() {
    this.router.navigate(['/']);
  }

  goDashboard() {
    this.router.navigate(['/user/dashboard']);
  }
}
