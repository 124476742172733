export interface FMSFNotification {
  id: number;
  userId: string;
  email: string;
  notificationType: FMSFNotificationType;
  details: string;
  // updateModels: UserPrivs[];
  saving: boolean
}

export enum FMSFNotificationType {
  NEW_USER = 'New User',
  MODEL_ACCESS = 'Model Access'
}
