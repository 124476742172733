import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpService} from "../../../services/http.service";
import {HttpHeaders} from "@angular/common/http";
import {ModelRun, modelRunStatus} from "../../../models/model-run";
import {catchError, finalize, Subscription, throwError} from "rxjs";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ModelType, UtilService} from "../../../services/util.service";
import {StateService} from "../../../services/state.service";
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-model-run-details',
  templateUrl: './model-run-details.component.html',
  styleUrls: ['./model-run-details.component.scss']
})
export class ModelRunDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  modelTypes : ModelType[] = [];
  modelSub!: Subscription;
  modelRunStatus = modelRunStatus;

  @ViewChild(MatTable) table!: MatTable<ModelRun>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['runId', 'owner', 'modelType', 'runStatus', 'statusMsg', 'created', 'started', 'completed', 'landscapeId', 'ignitionId', 'barrierId', 'limitingShape'];
  dataSource = new MatTableDataSource<ModelRun>();

  searching = false;

  constructor(private httpService : HttpService,
              public util : UtilService,
              public stateService: StateService) { }

  ngOnInit(): void {
    this.modelSub = this.util.modelTypeSub$.subscribe((mt: ModelType[]) => {
      this.modelTypes = mt;
    });

    if (!this.stateService.mrd_search_createdStart) {
      this.stateService.mrd_search_createdStart = new Date();
      this.stateService.mrd_search_createdStart.setDate(this.stateService.mrd_search_createdStart.getDate() - 14);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.modelSub.unsubscribe();
  }

  getModelRuns(params: any) {
    this.searching = true;
    Object.keys(params).forEach(key => !params[key] && delete params[key]);
    // need to covert dates to format expected by back end
    if (params.createdStart) {
      params.createdStart = formatDate(params.createdStart,'yyyy-MM-dd', 'en-US');
    }
    if (params.createdEnd) {
      params.createdEnd = formatDate(params.createdEnd,'yyyy-MM-dd', 'en-US');
    }

    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers, params };
    return this.httpService.Get<ModelRun[]>('/ui_api/admin/search_model_runs', options).pipe(
      finalize(() => this.searching = false),
      catchError(err => {
        return throwError(err);
      })
    ).subscribe((response : ModelRun[]) => {
      console.log(response);
      this.dataSource.data = response;
    });
  }

  applyFilter(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onSearch(params: any) {
    this.getModelRuns(params);
  }

  onExport() {
    this.util.exportToExcel(this.dataSource.data, "JobStatusResults");
  }
}
