import { Component, OnInit } from '@angular/core';
import {SidebarService} from "../../services/sidebar.service";
import {StateService} from "../../services/state.service";

@Component({
  selector: 'app-user-wrapper',
  templateUrl: './user-wrapper.component.html',
  styleUrls: ['./user-wrapper.component.scss']
})
export class UserWrapperComponent implements OnInit {

  constructor(private sidebarService : SidebarService,
              private stateService : StateService) { }

  ngOnInit(): void {
    // if first time sidebar enabled, set menu items, enable the toggle, and toggle the sidebar
    if (!this.sidebarService.enabled) {
      this.sidebarService.setNavByUser(this.stateService.user.role_id);
      this.sidebarService.enabled = true;
      this.sidebarService.toggle(true);
    }
  }

}
