import { Component, OnInit } from '@angular/core';
import {StateService} from "../../services/state.service";
import {UtilService} from "../../services/util.service";
import {HttpHeaders} from "@angular/common/http";
import {catchError, throwError} from "rxjs";
import {HttpService} from "../../services/http.service";

@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.scss']
})
export class ApiKeyComponent implements OnInit {
  public key! : string;

  constructor(public stateService:StateService,
              public util : UtilService,
              private httpService : HttpService) { }

  ngOnInit(): void {
    this.httpService.Get<any>('/ui_api/getkey/' + this.stateService.user.user_id).subscribe(response => {
      // console.log(response);
      this.key = response.message;
    });
  }

  genKey(value: any) {
    let newkey = this.util.randomKey();
    let body = { accessCode : newkey}
    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers };
    this.httpService.Put<void>('/ui_api/updatekey/' + this.stateService.user.user_id, body, options).pipe(
      catchError(err => {
        // console.log('Handling error locally.', err);
        return throwError(err);
      })
    ).subscribe(response => {
      this.key = newkey;
    });
  }

  onCopy() {
    this.util.copy(this.key);
  }
}
