import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../../services/state.service";
import {User, UserRoleString} from "../../models/user";
import {SidebarService} from "../../services/sidebar.service";
import {HttpService} from "../../services/http.service";
import {HttpHeaders} from "@angular/common/http";
import {catchError, throwError} from "rxjs";
import {UtilService} from "../../services/util.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, AfterViewInit {

  constructor(private router: Router,
              private stateService:StateService,
              private sidebarService : SidebarService,
              private httpService : HttpService,
              private util : UtilService) {

  }

  ngOnInit(): void {
    this.sidebarService.enabled = false;
    this.sidebarService.toggle(false);
  }

  ngAfterViewInit() {
    if (this.stateService.isValidUser()) {
      // temp comment so back button routes back to login page
      // this.onLogin({username: this.stateService.user.email})
    }
  }

  onLanding() {
    this.router.navigate(['/'] );
  }

  onLogin(value: any) {
    // Page 13 - login will need to generate a unique state value, then redirect to FamAuth
    // https://iwfirp-dev.nwcg.gov/oauth/authorize?client_id=acme& redirect_uri=http://<myapp_server>/login&response_type=code&state=28IHK4
    // Once logged in, or if already logged in, FamAuth wil redirect back to us with a code
    // http://<Client Web App>/login?code= FIHxR6&state=28IHK4
    // need to verify state matches
    // then via a backend call, POST info to get the access token
    // https://iwfirp-dev.nwcg.gov/oauth/token
    // headers: Authorization: 'Basic '+Have to base 64 encode here ‘CLIENT_ID:CLIENT_SECRET’
    // body/form data: grant_type: authorization_code, redirect_uri: http://10.0.75.1:9999/login, code: FIHxR6
    // then to get users profile, may be just email from login.gov, or all eAuth info
    // https:// iwfirp-dev.nwcg.gov/me?access_token=XXX...
    // use email to query back end and get users info from FMSF DB to complete user object and go to dashboard, redirect to registration, opr display mmessage

    // with multiple steps that don't have UI, have angular initiate the redirect to login, but then the spring backend
    // handle the rest. Then have Spring redirect to either the error page or the dashboard depending on results.
    // Backend then stores all the internal info and returns its own session token to be used by the app.
    // Then all secret internals are kept on the server instead of in the browser and a UI only token is shared.

    // this.stateService.user.email = value.username;
    // this.stateService.isLoggedIn = true;
    //
    // this.httpService.Get<User>(`/ui_api/userinfo_email/${value.username}`).subscribe(response => {
    //   console.log(response);
    //   let dest;
    //   if (response) {
    //     this.stateService.user = response;
    //     dest = '/' + UserRoleString[this.stateService.user.role_id] + '/dashboard';
    //   } else {
    //     dest = '/register';
    //   }
    //   this.router.navigate([dest] );
    // });

    // const headers = new HttpHeaders({'Content-Type':  'application/json'});
    // const options = { ...headers};
    // this.httpService.Post<User>('/ui_api/auth/signin', value, options).pipe(
    //   catchError(err => {
    //     // console.log('Handling error locally.', err);
    //     return throwError(err);
    //   })
    // ).subscribe(response => {
    //   // console.log(response);
    //   this.stateService.user = response;
    //   this.util.init();
    //   this.stateService.checkRedirect('/' + UserRoleString[this.stateService.user.role_id] + '/dashboard');
    // });
  }

}
