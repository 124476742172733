<!--<div fxFill fxLayout="column">-->
<!--<div fxLayoutAlign="space-evenly">-->
<div fxFill fxLayout="column">
  <div class="scrollable-content">
    <mat-card>
      <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-card-title role="heading" aria-level="1">Welcome to FMSF!</mat-card-title>
          <mat-card-subtitle role="heading" aria-level="2">Fire Modeling Services Framework</mat-card-subtitle>
        </div>
      </mat-card-header>
      <mat-card-actions fxLayout="row" fxLayoutAlign="center">
        <button *ngIf="!sideBarService.enabled" mat-raised-button color="primary" (click)="onLogin($event)"
                attr.aria-label="{{stateService.isLoggedIn && stateService.user.user_id !== '' ? 'My Account' : stateService.isLoggedIn ? 'Register' : 'Opens FAMAuth to Login'}}">
          {{stateService.isLoggedIn && stateService.user.user_id !== "" ? "My Account" : stateService.isLoggedIn ? "Register" : "Login"}}
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card>
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <div fxFlex="95%" fxLayout="column">
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px">
            <div fxFlex="50" fxFlex.lt-sm="">
              <h3 class="mat-h3 fmsf-mat-header">&nbsp;What is it?</h3>
              <!--            <div class="img-float">-->
              <!--              <img src="assets/img/fmsf_logo_big.jpg" width="129" alt="FMSF Logo">-->
              <!--              <p>xx</p>-->
              <!--            </div>-->

              <p>FMSF is a place to run wildland fire behavior and effects models for use within other systems. It brings improved performance, consistent data, and faster analysis results. FMSF also provides a key feature to users by greatly reducing the Information Technology (IT) needs by hosting models and tools in one service.</p>

              <p>Generally speaking: FMSF hosts wildland fire behavior and effects models and tools for Application Programming Interface (API) connections to other systems (e.g. IFTDSS, WFDSS, etc.). FMSF provides fire forecasting data (flame lengths, rates of spread, fire progression, tree mortality, fuels consumed, emissions, etc.) outputs for use in other application analyses. This structure (build once, use multiple times) saves time and costs.</p>

              <!--            <div class="image_block">-->
              <!--              <img src="assets/img/fmsf_framework.jpg" alt="View of the Fire Modeling Services Framework (FMSF) and hosted models/tools">-->
              <!--            </div>-->
              <!--            <span class="figure">Figure 1. Representative view of the Fire Modeling Services Framework (FMSF) and hosted models/tools</span>-->

              <p>Technically speaking: FMSF is a Model as a Service (MaaS) that provides an API through RESTful web-service architecture.</p>

              <h3 class="mat-h3 fmsf-mat-header">&nbsp;We have models!</h3>

              <ol>
                <li>FlamMap (FLAM)</li>
                <li>MTT (MTT_)</li>
                <li>Randig (RNDG)</li>
                <li>FSPro (FSPR)</li>
                <li>FARSITE (FARS)</li>
                <li>SpatialFOFEM Consumption and Emissions (FOFM)</li>
                <li>SpatialFOFEM Tree Mortality (SFTM)</li>
              </ol>

              <p>For more information on the models themselves, visit the <a href="https://www.firelab.org/" target="_blank">Missoula Fire Sciences Laboratory</a> where they were originally developed.</p>
            </div>

            <div fxFlex="50" fxFlex.lt-sm="">
              <h3 class="mat-h3 fmsf-mat-header">&nbsp;Would you like to know more?</h3>
              <p>For documentation on how to use the FMSF within your application, please see the <a href="https://fmsf.firenet.gov/help/" target="_blank">User Guide</a>.</p>

              <div class="image_block">
                <img src="assets/img/fmsf_website2.png" alt="Graphic of FMSF users with arrow pointing to the FMSF API and then another arrow pointing to a list of models">
              </div>
              <div class="image_block">
                <span class="figure">Figure 1. Diagram of how the Fire Modeling Services Framework (FMSF) fits within the larger decision support system ecosystem.</span>
              </div>


              <!--            <div class="image_block">-->
              <!--              <img src="assets/img/fmsf_apps.jpg" alt="View of the various services used in fire modeling">-->
              <!--            </div>-->
              <!--            <span class="figure">Figure 2. The Fire Modeling Services Framework (FMSF) is one of many services used by applications to run fire behavior and effects models. Specific to fire behavior and effects modeling, landscape data comes from the LANDFIRE Products Service (LFPS), and eventually fire environment data from the Fire Environment Mapping System (FEMS).</span>-->


            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <div fxFlex="95%" fxLayout="column">
          <div class="separator color_secondary"></div>
          <p>WARNING TO USERS OF THIS SYSTEM</p>
          <div class="separator color_secondary"></div>
          THIS IS A NOTICE OF MONITORING OF THE DEPARTMENT OF THE INTERIOR (DOI) INFORMATION SYSTEMS.<br>
          This computer system, including all related equipment, networks, and network devices (including Internet
          access), is provided by the Department of the Interior (DOI) in accordance with the agency policy for official
          use and limited personal use.<br>
          All agency computer systems may be monitored for all lawful purposes, including but not limited to, ensuring
          that use is authorized, for management of the system, to facilitate protection against unauthorized access, and
          to verify security procedures, survivability and operational security. Any information on this computer system
          may be examined, recorded, copied and used for authorized purposes at any time.<br>
          All information, including personal information, placed or sent over this system may be monitored, and users
          of this system are reminded that such monitoring does occur. Therefore, there should be no expectation of
          privacy with respect to use of this system.<br>
          By logging into this agency computer system, you acknowledge and consent to the monitoring of this system.
          Evidence of your use, authorized or unauthorized, collected during monitoring may be used for civil, criminal,
          administrative, or other adverse action. Unauthorized or illegal use may subject you to prosecution.
          <a href="https://bugcrowd.com/owf-vdp" target="_blank">Vulnerability Disclosure Policy</a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <mat-toolbar color="primary" fxFlex fxLayoutAlign="flex-end">
    <mat-toolbar-row>
      <div fxLayout="column" fxLayoutGap="2px" class="footer_text">
        <div class="mat-h3 bg_color_accent footer_title clip_bg_right_arrow" role="heading" aria-level="3">&nbsp;Learn More</div>
        <a href="assets/FMSFbriefingPaper.pdf" target="_blank">Briefing Paper</a>
        <a href="https://youtu.be/ra-i_tPZdUw" target="_blank">Overview Webinar</a>
      </div>
      <div fxFlex fxLayoutAlign="flex-end" fxLayoutGap="4px" class="footer_logo">
        <img src="assets/img/usda_logo.png" alt="US Department of Agriculture Logo" style="background: white">
        <img src="assets/img/fs_logo.png" alt="Forest Service Logo">
        <img src="assets/img/doi_seal.png" alt="US Department of the interior Logo">
        <img src="assets/img/owf_logo.png" alt="Interior wild land fire Logo">
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
