import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StateService} from "./state.service";

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuardService implements CanActivate {
  environment!: string;

  constructor(private router: Router, private stateService:StateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.environment = document.location && document.location.host.substr(3, 4);

    // only a user who is logged in, but does not have a FMSF_UI account yet can access
    if (this.stateService.isLoggedIn && this.stateService.user.user_id == "") {
      return true;
    } else {
      this.stateService.redirectUrl = state.url;
      this.router.navigate(['/unauthorized'] );
      return false;
    }

  }
}
