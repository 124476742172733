import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

/*
This is a generic confirm modal which can be used across the app by injecting CustomModalService and using its open() or confirmed()
methods. Parent component can use CustomModalService's open() method by passing param of type MatDialogConfig and can subscribe to confirmed() method.
 */

export interface GenericDialogData {
  cancelText?: string;
  confirmText?: string;
  message: string;
  title: string;
  confirmButtonColor?: string;
  cancelButtonColor?: string;
}

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {
  confirmButtonColorString: string;
  cancelButtonColorString: string;
  modalIconNameString: string;
  btnStatus = false;
  notDisplayCancelBtn = false;
  subtitleTextColor: string;
  getResponse : boolean;
  response: string = "";
  checkBoxVal: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<CustomModalComponent>,
    public dialog: MatDialog,
  ) {
    this.confirmButtonColorString = this.data.confirmButtonColor;
    this.cancelButtonColorString = this.data.cancelButtonColor;
    this.modalIconNameString = this.data.modalIconName;
    this.notDisplayCancelBtn = this.data.notDisplayCancelBtn;
    this.subtitleTextColor = this.data.subtitleTextColor;
    this.getResponse = this.data.getResponse;
  }

  ngOnInit(): void {}

  public cancel() {
    this.close(false);
  }

  public close(value: any) {
    this.mdDialogRef.close(value);
  }

  public confirm() {
      this.close(this.data);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    if (!this.data.ignoreESC){
      this.close(false);
    }
  }
}
