import {Component, OnDestroy, OnInit} from '@angular/core';
import {StateService} from "../../services/state.service";
import {User} from "../../models/user";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpHeaders} from "@angular/common/http";
import {HttpService} from "../../services/http.service";
import {catchError, finalize, Subscription, throwError} from "rxjs";
import {OrgList, UtilService} from "../../services/util.service";
import {CustomModalService} from "../../services/custom-modal.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  public orgList : OrgList[] = [];
  orgSub!: Subscription;
  newUser: boolean = false;
  regUser: string|undefined = undefined;
  saving = false;

  constructor(private router: Router,
              private stateService:StateService,
              private httpService : HttpService,
              private util : UtilService,
              private activatedRoute: ActivatedRoute,
              private modalService : CustomModalService) { }

  ngOnInit(): void {
    this.orgSub = this.util.orgListSub$.subscribe((ol: OrgList[]) => {
      this.orgList = ol;
    });

    if (this.activatedRoute.snapshot.url[0].path.indexOf("user-new") != -1) {
      this.newUser = true;
    } else {
      this.regUser = this.stateService.user.email;
    }

  }

  ngOnDestroy(): void {
    this.orgSub.unsubscribe();
  }

  onRegistration(body: any) {
    this.saving = true;
    body.accessCode = this.util.randomKey();
    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers };
    return  this.httpService.Post<User>('/ui_api/reguser', body, options).pipe(
      finalize(() => this.saving = false),
      catchError(err => {
        if (err.includes("SMTP") || err.includes("Error")) {
          this.util.displayDialogMessage("Error: Email notification error",
            "You have successfully registered, but the email notification for your request was not sent. Please contact support and let them know to approve your account.");
        }
        return throwError(err);
      })
    ).subscribe(response => {
      if (this.newUser) {
        this.router.navigate(['/admin/user-edit/' + body.username] );
      } else {
        // user registered, make sure all temp auth is logged out
        this.regConfirmed();
      }
    });

  }

  setVars(value: any) {
    if (value.role || value.role === 0) {
      this.stateService.userRole = value.role;
    }
    if (value.status || value.status === 0) {
      this.stateService.userStatus = value.status;
    }
    if (value.first) {
      this.stateService.user.first_name = value.first;
    }
    if (value.last) {
      this.stateService.user.last_name = value.last;
    }
    if (value.key) {
      this.stateService.user.userInfo.access_code = value.key;
    }
    if (value.super || value.super === 0) {
      this.stateService.user.userInfo.superuser = !!value.super;
    }
  }

  regConfirmed() {
    this.util.displayDialogMessage("Thank you for registering!",
      "Approval can take up to two working days and an email will be sent when completed.")
      .subscribe(res => {
      // console.log(res);
      if (res) {
        this.stateService.doLogout();
      }
    });
  }
}
