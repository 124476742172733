import { Component, OnInit } from '@angular/core';
import {UserRoleString} from "../../models/user";
import {Router} from "@angular/router";
import {StateService} from "../../services/state.service";
import {SidebarService} from "../../services/sidebar.service";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(private router: Router,
              public stateService : StateService,
              public sideBarService: SidebarService) { }

  ngOnInit(): void {
  }

  onLogin(event: MouseEvent) {
    if (this.stateService.isLoggedIn && this.stateService.user.user_id !== "") {
      this.router.navigate(['/' + UserRoleString[this.stateService.user.role_id] + '/dashboard'] );
    } else if (this.stateService.isLoggedIn) {
      this.router.navigate(['/register'] );
    } else {
      // if (event.shiftKey && event.altKey && event.ctrlKey) {
      //   this.router.navigate(['/login'] );
      // } else {
        window.open('/fmsf_ui/oauth2/authorization/custom', '_self');
      // }
    }
  }
}
