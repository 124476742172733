import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModelType, UtilService} from "../../services/util.service";
import {Subscription} from "rxjs";
import {UserPrivs} from "../../models/user";
import {StateService} from "../../services/state.service";

@Component({
  selector: 'app-current-models',
  templateUrl: './current-models.component.html',
  styleUrls: ['./current-models.component.scss']
})
export class CurrentModelsComponent implements OnInit, OnDestroy {
  modelTypes : ModelType[] = [];
  modelSub!: Subscription;
  userPrivs : UserPrivs[] = [];
  userPrivsSub!: Subscription;
  note : string = "";
  noteSub!: Subscription;

  constructor(public stateService : StateService,
              public util : UtilService) { }

  ngOnInit(): void {
    this.modelSub = this.util.modelTypeSub$.subscribe((mt: ModelType[]) => {
      this.modelTypes = mt;
    });
    this.userPrivsSub = this.util.userPrivsSub$.subscribe((up: UserPrivs[]) => {
      this.userPrivs = up;
    });
    this.noteSub = this.util.notificationSub$.subscribe((up: string) => {
      this.note = up;
    });
  }

  ngOnDestroy(): void {
    this.modelSub.unsubscribe();
    this.userPrivsSub.unsubscribe();
    this.noteSub.unsubscribe();
  }

}
