import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./components/login/login.component";
import {RegisterComponent} from "./components/register/register.component";
import {WildcardErrorPageComponent} from "./components/wildcard-error-page/wildcard-error-page.component";
import {AdminDashboardComponent} from "./components/admin/admin-dashboard/admin-dashboard.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {AdminWrapperComponent} from "./components/admin/admin-wrapper/admin-wrapper.component";
import {UserWrapperComponent} from "./components/user-wrapper/user-wrapper.component";
import {UserAuthGuardService} from "./services/user-auth-guard.service";
import {UnauthorizedUserComponent} from "./components/unauthorized-user/unauthorized-user.component";
import {AdminAuthGuardService} from "./services/admin-auth-guard.service";
import {ApiKeyComponent} from "./components/api-key/api-key.component";
import {AccessRequestComponent} from "./components/access-request/access-request.component";
import {UserSearchComponent} from "./components/admin/user-search/user-search.component";
import {UserEditComponent} from "./components/admin/user-edit/user-edit.component";
import {LoginAuthGuardService} from "./services/login-auth-guard.service";
import {LandingComponent} from "./components/landing/landing.component";
import {ReleaseNotesComponent} from "./components/release-notes/release-notes.component";
import {SysNoteComponent} from "./components/admin/sys-note/sys-note.component";
import {ModelRunDetailsComponent} from "./components/admin/model-run-details/model-run-details.component";

const routes: Routes = [
  {path: '', component: LandingComponent, pathMatch: 'full', data: {title:'Landing'}},
  {path: 'login', component: LoginComponent, data: {title:'Login'}},
  {path: 'rn', component: ReleaseNotesComponent, data: {title:'Release Notes'}},
  // normally odd to require user to be logged in to register, but in the normal user case they wi/l be logged in via 2FA via FAMAuth
  // so a user will always be logged in, just not have an FMSF user account and will be redirected to register
  {path: 'register', component: RegisterComponent, pathMatch: 'full', data: {title:'Register'}, canActivate: [LoginAuthGuardService]},

  {path: 'user', component: UserWrapperComponent, canActivate: [UserAuthGuardService],
    children : [
      {path: 'dashboard', component: DashboardComponent, data: {title:'Dashboard'}},
      {path: 'apikey', component: ApiKeyComponent, data: {title:'Api Key'}},
      {path: 'accessreq', component: AccessRequestComponent, data: {title:'Model Access'}}
    ]},

  {path: 'admin', component: AdminWrapperComponent, canActivate: [UserAuthGuardService, AdminAuthGuardService],
  // {path: 'admin', component: AdminWrapperComponent,
    children : [
      {path: 'dashboard', component: AdminDashboardComponent, data: {title:'Dashboard'}},
      {path: 'user-search', component: UserSearchComponent, data: {title:'User Search'}},
      {path: 'user-edit/:user_id', component: UserEditComponent, data: {title:'User Edit'}},
      {path: 'user-new', component: RegisterComponent, data: {title:'New User'}},
      {path: 'sys-note', component: SysNoteComponent, data: {title:'System Notification'}},
      {path: 'model-run-details', component: ModelRunDetailsComponent, data: {title:'Model Run Details'}}
      // {path: 'themes' , canActivate: [UserAuthGuardService], component: ThemeSelectorComponent},
      // {path: 'buildinfo' , component: AboutComponent},
    ]},

  { path: 'unauthorized', component: UnauthorizedUserComponent, data: {title:'Unauthorized'}},
  { path: 'error', component: WildcardErrorPageComponent, data: {title:'Error'}},
  { path: '**', component: WildcardErrorPageComponent, data: {title:'404'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
