export interface ModelRun {
  runId: number;
  owner: string;
  modelType: string;
  runStatus: string;
  created: string;
  started: string;
  completed: string;
  landscapeId: number;
  statusMsg: string;
  deleted: boolean
  resampleId: number;
  localWorkDir: string;
  canDelete: boolean;
  ignitionId: string;
  barrierId: string;
  limitingShape: string;
}

export const modelRunStatus = ['qued', 'init', 'run_', 'done', 'fail', 'cncl'];
