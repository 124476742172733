<div [hidden]="dataSource.data.length == 0" class="fmsf_mat_override">
  <mat-card>
    <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-card-title role="heading" aria-level="1">Notifications</mat-card-title>
<!--        <mat-card-subtitle role="heading" aria-level="2"></mat-card-subtitle>-->
      </div>
    </mat-card-header>
    <mat-card-content class="mat-elevation-z8">
      <div fxLayout="column" fxLayoutGap="16px">
        <table mat-table matSort [dataSource]="dataSource" class="search-table" (matSortChange)="util.announceSortChange($event)">
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Type"> Type </th>
            <td mat-cell *matCellDef="let note"> {{note.notificationType}} </td>
          </ng-container>
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by User"> User </th>
            <td mat-cell *matCellDef="let note"> {{note.userId}} </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email"> Email </th>
            <td mat-cell *matCellDef="let note"> {{note.email}} </td>
          </ng-container>
          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef> Details </th>
            <td mat-cell *matCellDef="let note"> {{note.details}} </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row">
              <button mat-raised-button type="button" color="primary" (click)="onApprove(row)" [disabled]="row.saving">Approve</button>
              <button mat-raised-button type="button" color="primary" (click)="onDeny(row)" [disabled]="row.saving">Deny</button>
              <button mat-raised-button type="button" color="primary" (click)="onEditUser(row)" [disabled]="row.saving">Edit</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">No data or nothing that matches the filter "{{input.value}}"</td>
          </tr>
        </table>

        <div fxLayout width="100%">
          <div fxLayoutAlign="start" fxFlex="20">
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Filter</mat-label>
              <input matInput type="text" (keyup)="applyFilter($event)" #input>
            </mat-form-field>
          </div>

          <div fxLayoutAlign="end" fxFlex="80">
            <mat-paginator appPaginationSave [lsKey]="'notification_page_size'" [defaultSize]="5" [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons aria-label="Select page of notifications"></mat-paginator>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
