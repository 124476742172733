import { Component, OnInit } from '@angular/core';
import {HttpHeaders} from "@angular/common/http";
import {catchError, finalize, throwError} from "rxjs";
import {HttpService} from "../../../services/http.service";
import {UtilService} from "../../../services/util.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-sys-note',
  templateUrl: './sys-note.component.html',
  styleUrls: ['./sys-note.component.scss']
})
export class SysNoteComponent implements OnInit {
  saving = false;

  constructor(private httpService : HttpService,
              private util : UtilService,
              private router: Router) { }

  ngOnInit(): void {
  }

  onSendMail(value: any) {
    console.log(value);
    this.saving = true;

    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers };
    return  this.httpService.Post<String>('/ui_api/admin/send_mail', value, options).pipe(
      finalize(() => this.saving = false),
      catchError(err => {
        if (err.includes("SMTP")) {
          this.util.displayDialogMessage("Error: Email notification error",
            "The email notification for your request was not sent. Try again and if it persists investigate possible AWS SES issue.");
        }
        return throwError(err);
      })
    ).subscribe(response => {
      console.log(response);
      this.util.displayDialogMessage("Qapla' !",
        "Notification sent.").subscribe(res => {
          this.router.navigate(['/admin/dashboard'] );
      });
    });
  }
}
