<div class="fmsf-full-screen" fxFill fxLayout="column">
  <app-header></app-header>
  <mat-sidenav-container class="fmsf-app-mat-drawer-container" [autosize]="sideBarService.autosize">
    <mat-sidenav mode="side" #drawer>
      <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
      <div role="main" fxFill id="mainContent">
        <router-outlet class="router-flex"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
