import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpHeaders} from "@angular/common/http";
import {
  User,
  UserOutputDefault,
  UserPreference,
  UserPrivs
} from "../../../models/user";
import {catchError, Observable, Subscription, throwError} from "rxjs";
import {HttpService} from "../../../services/http.service";
import {ModelType, OrgList, UtilService} from "../../../services/util.service";
import {NgForm} from "@angular/forms";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {CustomModalService} from "../../../services/custom-modal.service";
import {StateService} from "../../../services/state.service";
import { FMSFNotification, FMSFNotificationType } from 'src/app/models/notification';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy, AfterViewInit {
  user_id! : string;

  modelTypes : ModelType[] = [];
  modelSub!: Subscription;
  orgList : OrgList[] = [];
  orgSub!: Subscription;

  accessRequest : UserPrivs[] = [];
  accessRequestResponse! : UserPrivs[];
  accessRequestPending : FMSFNotification | undefined = undefined;
  FMSFNotificationType: typeof FMSFNotificationType = FMSFNotificationType;

  user? : User;
  role! : string;
  status! : string;
  superuser! : string;

  userPref? : UserPreference;

  savingUser = false;
  savingModels = false;
  savingPref = false;
  canSaveOutputDefault : boolean = false;

  @ViewChild('fUser', { read: NgForm }) fUser!: NgForm;
  @ViewChild('fModels', { read: NgForm }) fModels!: NgForm;


  constructor(private activatedRoute: ActivatedRoute,
              private httpService : HttpService,
              public util : UtilService,
              private modalService : CustomModalService,
              private router : Router,
              private stateService : StateService) { }

  ngOnInit(): void {
    this.user_id = this.activatedRoute.snapshot.params['user_id'];
    this.accessRequestPending = this.stateService.editUserAccessRequestPending;
    // console.log(user_id);

    this.modelSub = this.util.modelTypeSub$.subscribe((mt: ModelType[]) => {
      this.modelTypes = mt;
      this.setAccessRequest();
    });
    this.orgSub = this.util.orgListSub$.subscribe((ol: OrgList[]) => {
      this.orgList = ol;
    });

    // get user info
    this.getData<User>('admin/userinfo_id/' + this.user_id).subscribe(response => {
      // console.log(response);
      this.setUser(response);
    });
    // get users models
    this.getData<UserPrivs[]>('admin/user_models/' + this.user_id).subscribe(response => {
      // console.log(response);
      this.accessRequestResponse = response;
      this.setAccessRequest();
    });
    // get pending requests - to cover situation where an admin may skip the notifications to edit the user
    // If using the notification edit button, this may return empty as the notification is being cleared
    this.getData<FMSFNotification>('admin/notification/' + this.user_id).subscribe(response => {
      console.log(response);
      if (response) {
        this.accessRequestPending = response;
      }
    });

  }

  ngOnDestroy(): void {
    this.modelSub.unsubscribe();
    this.orgSub.unsubscribe();
  }

  ngAfterViewInit() {

  }

  pathDataAccessor(item: any, path: string): any {
    return path.split('.')
      .reduce((accumulator: any, key: string) => {
        return accumulator ? accumulator[key] : undefined;
      }, item);
  }

  filterData(data: any, filter: string):boolean {
    let match = false;

    Object.keys(data).forEach(prop => {
      if (typeof data[prop] == "object"  && data[prop] !== null && !match) {
        match = this.filterData(data[prop], filter);
      } else {
        if (data[prop].trim().toLowerCase().indexOf(filter) != -1) {
          match = true;
        }
        if (prop == "gridded" && this.util.superuser[data[prop]].trim().toLowerCase().indexOf(filter) != -1) {
          // extended special case to match gridded 'true' and 'false'
          match = true;
        }
        if (prop == "modelType") {
          // extended special case to match modelType short and common names
          let model = this.modelTypes.find(m => m.id === data[prop]);
          if (model) {
            let combinedString = model.shortName + '$' + model.commonName;
            if (combinedString.trim().toLowerCase().indexOf(filter) != -1) {
              match = true;
            }
          }
        }
      }
    });
    return match;
  }

  getData<T>(url : string): Observable<T> {
    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers };
    return this.httpService.Get<T>('/ui_api/' + url, options).pipe(
      catchError(err => {
        // console.log('Handling error locally.', err);
        return throwError(err);
      })
    );
  }

  setData<T>(url: string, body: any): Observable<T> {
    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers };
    return  this.httpService.Post<T>('/ui_api/' + url, body, options).pipe(
      catchError(err => {
        // console.log('Handling error locally.', err);
        return throwError(err);
      })
    );
  }

  setUser(response : User) {
    if (response == null) {
      this.util.displayDialogMessage("Error", "User doesn't exist");
      return;
    }
    this.user = response;
    this.role = response.role_id.toString();
    this.status = response.account_status_id.toString();
    this.superuser = response.userInfo.superuser ? "1" : "0";
  }

  // {modelType : "", concurrentRuns : 0}
  setAccessRequest() {
    if (this.accessRequestResponse) {
      this.modelTypes.forEach(modelType => {
        let userModel = this.accessRequestResponse.find(userPriv => userPriv.id.modelType === modelType.id);
        this.accessRequest.push({id : {userId : this.user_id, modelType : modelType.id}, concurrentRuns : userModel?.concurrentRuns || 0})
      })
    }
  }

  onSaveUser(body: any) {
    this.savingUser = true;
    // console.log(body);
    // convert superuser back to boolean
    body.superuser = body.superuser === "1";

    this.setData("admin/update_user/" + this.user?.user_id, body).subscribe(response => {
      // console.log(response);
      this.fUser.form.markAsPristine();
      this.fUser.form.markAsUntouched();

      if (this.accessRequestPending && this.accessRequestPending.notificationType === FMSFNotificationType.NEW_USER) {
        this.util.updateNotification(this.accessRequestPending, "edit", "").subscribe(response => {
          this.accessRequestPending = undefined;
        });
      }

      this.savingUser = false;
    });
  }

  onSaveModels(value: any) {
    // not using passed in value, just here for testing. ngModel updating accessRequest
    // console.log(value);
    this.savingModels = true;
    this.accessRequest.forEach(req => {
      if (!req.concurrentRuns) req.concurrentRuns = 0;
    })
    // console.log(this.accessRequest);
    this.setData("admin/update_models/" + this.user?.user_id, this.accessRequest).subscribe(response => {
      // console.log(response);
      this.fModels.form.markAsPristine();
      this.fModels.form.markAsUntouched();
      if (this.user?.user_id === this.stateService.user.user_id) {
        // user just edited self, call init in utils to refresh data
        // this.util.init(false);
        this.util.getMyModelInfo();
      }

      if (this.accessRequestPending && this.accessRequestPending.notificationType === FMSFNotificationType.MODEL_ACCESS) {
        this.util.updateNotification(this.accessRequestPending, "edit", "").subscribe(response => {
          this.accessRequestPending = undefined;
        });
      }

      this.savingModels = false;
    });
  }

  onDeleteUser() {
    this.util.displayDialogMessage("DANGER!!: Deleting User!",
      "This will delete this user's user_info, preference, privileges, and output defaults.<br>" +
      "       It may fail if other tables have been populated, like from models being run.<br>" +
      "       Are you sure you wish to proceed?",
      "Are you sure?", "DELETE", true, "Cancel", "warn", "primary")
      .subscribe(res => {
      // console.log(res);
      if (res) {
        const headers = new HttpHeaders({'Content-Type':  'application/json'});
        const options = { ...headers };
        this.httpService.Delete<void>('/ui_api/admin/delete_user/' + this.user_id, options).pipe(
          catchError(err => {
            // console.log('Handling error locally.', err);
            return throwError(err);
          })
        ).subscribe(() => {
          this.router.navigate(['/admin/user-search/'] );
        })
      }
    });
  }
}
