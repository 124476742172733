<div>
  <form #f="ngForm" (ngSubmit)="onSendMail(f.value)">
    <mat-card>
      <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-card-title role="heading" aria-level="1">System Notification</mat-card-title>
          <!--          <mat-card-subtitle></mat-card-subtitle>-->
        </div>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <div fxFlex="90" fxLayout="row" fxLayoutGap="16px">
          <div fxLayout="column" fxFlex="100">
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Subject</mat-label>
              <input matInput name="subject" ngModel type="text">
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Message</mat-label>
              <textarea matInput name="message" ngModel type="text" class="message_layout"></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="center" fxLayoutGap="16px">
        <button mat-raised-button type="submit" color="primary" [disabled]="!f.valid || !f.dirty || saving">Send Notification</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
