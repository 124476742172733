<div>
  <p *ngIf="!newUser">*Info from auth system will pre-populate where available</p>
  <form #f="ngForm" (ngSubmit)="onRegistration(f.value)">
    <mat-card>
      <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-card-title *ngIf="!newUser">FMSF Registration</mat-card-title>
          <mat-card-title *ngIf="newUser">FMSF New User</mat-card-title>
          <mat-card-subtitle *ngIf="!newUser">Please fill out your information for an FMSF admin to approve</mat-card-subtitle>
        </div>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <div fxFlex="75%" fxLayout="column">
          <mat-checkbox name="agree" ngModel required class="fmsf_mat_override">
            The FMSF was created for developers to incorporate into applications. Because of this, there is no user interface to generate model inputs or view model outputs. However, analysts and researchers may obtain accounts, but must possess the skills to use a RESTful web-service. Select if you understand and still wish to register an account.
          </mat-checkbox>

          <br>
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Email</mat-label>
            <input #email="ngModel" matInput name="email" ngModel type="email" required appEmailExist [ngModel]="regUser">
            <mat-error *ngIf="email.errors?.['exists']">
              Email "{{email.value}}" is already in use
            </mat-error>
          </mat-form-field>

          <br>
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput name="firstname" ngModel type="text" required>
          </mat-form-field>

          <br>
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput name="lastname" ngModel type="text" required>
          </mat-form-field>

          <br>
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Organization</mat-label>
            <mat-select name="org" ngModel required>
              <mat-option *ngFor="let org of orgList" [value]="org.org_name">
                {{org.org_name}}
              </mat-option>
            </mat-select>
            <mat-hint>Select organization you represent</mat-hint>
          </mat-form-field>

          <br>
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Username</mat-label>
            <input #user="ngModel" matInput name="username" ngModel type="text" required maxlength="32" appUserExist>
            <mat-hint>User name for API access. Max 32 characters. {{user.value?.length || 0}}/32</mat-hint>
            <mat-error *ngIf="user.errors?.['exists']">
              Username "{{user.value}}" is already in use
            </mat-error>
          </mat-form-field>

          <br>
          <mat-form-field floatLabel="always" appearance="outline" *ngIf="!newUser">
            <mat-label>Justification</mat-label>
            <textarea #just matInput name="justification" ngModel required maxlength="1000"></textarea>
            <mat-hint>{{just.value.length || 0}}/1000</mat-hint>
          </mat-form-field>

        </div>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="center">
        <button mat-raised-button type="submit" color="primary" [disabled]="!f.valid || !f.dirty || saving">Register</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>



