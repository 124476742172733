import {Component, OnInit} from '@angular/core';
import {CustomModalService} from "../../services/custom-modal.service";
import {CustomModalComponent} from "../custom-modal/custom-modal.component";
import {SidebarService} from "../../services/sidebar.service";
import {StateService} from "../../services/state.service";
import {Router} from "@angular/router";
import {UtilService} from "../../services/util.service";
import {UserStatus} from "../../models/user";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  UserStatus: typeof UserStatus = UserStatus;

  constructor(public util : UtilService,
              public sideBarService:SidebarService,
              public stateService : StateService,
              private router: Router) { }

  ngOnInit(): void {
  }

  onToggleDrawer() {
    this.sideBarService.toggle();
  }

  onContactUs() {
    setTimeout (() => {
      this.util.displayDialogMessage("Contact Us",
        "If you have questions or Section 508 needs with FMSF,<br>" +
        "please email <a href='mailto:OCIO-EAS-FMSF-Admins@usda.gov'>OCIO-EAS-FMSF-Admins@usda.gov</a>");
    }, 0);
  }

  onReleaseNotes() {
    // window.open('https://fmsf.firenet.gov/landing_page/release-notes.html', '_blank');
    this.router.navigate(['/rn'] );
  }

  onUserSupport() {
    // window.open('https://fmsf.firenet.gov/firenetHelp/help/pageHelp/content/home.htm?cshid=1000', '_blank');
   window.open('https://fmsf.firenet.gov/help/', '_blank');
    // window.open('https://fmsf.firenet.gov/fmsf_ui/docs/Default.htm', '_blank');
  }

  onSwagger() {
    window.open('/swagger', '_blank');
  }

  onLogout() {
    this.util.displayDialogMessage("Logging out", "This will log you out of FMSF, but not FAMAuth",
      "Are you sure you wish to logout?", "Yes", true, "Cancel")
    .subscribe(res => {
      if (res) {
        this.stateService.doLogout();
      }
    });
  }
}
