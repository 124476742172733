import { Injectable } from '@angular/core';
import {
  HttpClient, HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest, HttpXsrfTokenExtractor
} from '@angular/common/http';
import {catchError, Observable, of, retry, throwError} from 'rxjs';
import {Router} from "@angular/router";
import {StateService} from "./state.service";
import {UtilService} from "./util.service";

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: any;
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

@Injectable({
  providedIn: 'root'
})

export class HttpService  {
  private readonly _serverURL: string;
  private readonly _isLocal: boolean = false;

  constructor(public http: HttpClient) {
    console.log(window.location.origin);
    // if (window.location.origin.indexOf('localhost') > 0 || window.location.origin.indexOf('127.0.0.1') > 0) {
    // localhost/127.0.0.1 not a good check if running from tomcat. check for angular ng port
    if (window.location.origin.indexOf('4200') > 0) {
      this._serverURL = 'http://localhost:8080/fmsf_ui';
      this._isLocal = true;
    } else {
      this._serverURL = window.location.origin + "/fmsf_ui";
    }
  }

  get serverURL(): string {
    return this._serverURL;
  }

  get isLocal(): boolean {
    return this._isLocal;
  }

  public Get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.get<T>(this._serverURL + endPoint, options);
  }

  public GetRaw<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.get<T>(endPoint, options);
  }

  public PostRaw<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    return this.http.post<T>( endPoint, params, options);
  }

  public Post<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    return this.http.post<T>(this._serverURL + endPoint, params, options);
  }

  public Put<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    return this.http.put<T>(this._serverURL + endPoint, params, options);
  }

  public Delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.delete<T>(this._serverURL + endPoint, options);
  }

}

@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {
  headerName : string = 'X-XSRF-TOKEN';

  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      withCredentials: true,
      setHeaders: this.checkHeader(request)
    });

    return next.handle(request);
  }

  checkHeader(request: HttpRequest<any>) {
    let method = request.method;
    let token = this.tokenExtractor.getToken() as string;
    if (method && (method === 'POST' || method === 'DELETE' || method === 'PUT' ) && (token !== null && !request.headers.has(this.headerName)) ) {
      return { [this.headerName] : token };
    } else {
      return undefined;
    }
  }
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private router : Router,
              private stateService : StateService,
              private util : UtilService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let handled: boolean = false;
    let req = request;

    return next.handle(request)
      .pipe(
        retry(1),
        catchError((returnedError) => {
          let errorMessage = null;

          if (returnedError.error instanceof ErrorEvent) {
            // client side
            errorMessage = `Error: ${returnedError.error.message}`;
          } else if (returnedError instanceof HttpErrorResponse) {
            // server side
            errorMessage = `Error Status ${returnedError.status}: ${returnedError.error} - ${returnedError.message}`;
            handled = this.handleServerSideError(returnedError);
          }

          console.error(errorMessage ? errorMessage : returnedError);

          if (!handled) {
            if (errorMessage) {
              return throwError(errorMessage);
            } else {
              return throwError("Unexpected problem occurred");
            }
          } else {
            return of(returnedError);
          }
        })
      )
  }

  private handleServerSideError(error: HttpErrorResponse): boolean {
    let handled: boolean = false;

    console.log(error.status);
    if (error.status == 0) {
      handled = true;
      this.util.displayDialogMessage("Unknown error",
        "Possible communication error with server. Try again later or contact support if it persists.");
    } else if (error.status == 401) {
      handled = true;
      console.log(this.router.url)
      const ignoredRoutes = "/unauthorized,/login,/rn";

      if (this.router.url == "/") {
        this.stateService.userLogout();
      } else if (ignoredRoutes.indexOf(this.router.url) == -1) {
        this.displayErrorAndLogout();
      }
    } else if (error.status == 403) {
      handled = true;
    } else if (error.status >= 500 && error.status <=599) {
      handled = true;
      this.util.displayDialogMessage("Server error",
        "Error returned from server. Try again later or contact support if it persists.", "Code: " + error.status);
    }

    return handled;
  }

  displayErrorAndLogout() {
    this.util.displayDialogMessage("User logged out", "User has been logged out")
      .subscribe(res => {
      // console.log(res);
      if (res) {
        this.stateService.userLogout();
      }
    });
  }
}
