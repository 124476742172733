<span *ngIf="modalIconNameString && modalIconNameString!=''">
  <div><mat-icon class="modal-icon">{{modalIconNameString}}</mat-icon></div>
</span>
<div class="title" fxLayout="row" fxLayoutAlign="start" cdkFocusInitial>
  <span mat-dialog-title role="heading" aria-level="1">{{ data.title }}</span>
</div>
<div class="separator color_secondary"></div>
<div class="sub-title" fxLayout="row" fxLayoutAlign="start" *ngIf="data && data.subtitle">
  <span mat-dialog-title [ngStyle]="{'color': data.subtitleTextColor}" role="heading" aria-level="2">{{ data.subtitle }}</span>
</div>
<div *ngIf="data && data.message" fxLayout="row" fxLayoutAlign="center center">
  <p class="dialog-message" [innerHTML]="data.message"></p>
</div>
<div *ngIf="getResponse">
  <textarea type="text" class="response_layout" [(ngModel)]="data.response"></textarea>
</div>
<div mat-dialog-content>
  <ng-container *ngComponentOutlet="data.component" filterClick></ng-container>
</div>
<div *ngIf="data && data.checkBox && data.cbText">
  <mat-checkbox name="agree" [(ngModel)]="checkBoxVal" required class="fmsf_mat_override">
    {{data.cbText}}
  </mat-checkbox>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button *ngIf ="!notDisplayCancelBtn" mat-raised-button [color]="cancelButtonColorString"  (click)="cancel()"
          [disabled]="data.checkBox && !checkBoxVal">{{ data.cancelText }}</button>
  <button mat-flat-button [color]="confirmButtonColorString" (click)="confirm()"
          [disabled]="data.checkBox && !checkBoxVal">{{ data.confirmText }}</button>
</div>
