import { NgModule } from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LoginComponent } from './components/login/login.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {NoopAnimationsModule} from "@angular/platform-browser/animations";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatListModule} from "@angular/material/list";
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import { RegisterComponent } from './components/register/register.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AdminDashboardComponent } from './components/admin/admin-dashboard/admin-dashboard.component';
import { WildcardErrorPageComponent } from './components/wildcard-error-page/wildcard-error-page.component';
import { AdminWrapperComponent } from './components/admin/admin-wrapper/admin-wrapper.component';
import { UserWrapperComponent } from './components/user-wrapper/user-wrapper.component';
import { UnauthorizedUserComponent } from './components/unauthorized-user/unauthorized-user.component';
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import { ApiKeyComponent } from './components/api-key/api-key.component';
import { AccessRequestComponent } from './components/access-request/access-request.component';
import { UserSearchComponent } from './components/admin/user-search/user-search.component';
import { UserEditComponent } from './components/admin/user-edit/user-edit.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {UserExistDirective} from './directives/user-exist.directive';
import { EmailExistDirective } from './directives/email-exist.directive';
import {MatSelectModule} from "@angular/material/select";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import { LandingComponent } from './components/landing/landing.component';
import {HttpErrorInterceptor, WithCredentialsInterceptor} from "./services/http.service";
import { ReleaseNotesComponent } from './components/release-notes/release-notes.component';
import {A11yModule} from "@angular/cdk/a11y";
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { SysNoteComponent } from './components/admin/sys-note/sys-note.component';
import { AdminNotificationsComponent } from './components/admin/admin-notifications/admin-notifications.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { PaginationSaveDirective } from './directives/pagination-save.directive';
import { ModelRunDetailsComponent } from './components/admin/model-run-details/model-run-details.component';
import { ModelRunStatusComponent } from './components/model-run-status/model-run-status.component';
import { CurrentModelsComponent } from './components/current-models/current-models.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    LoginComponent,
    CustomModalComponent,
    RegisterComponent,
    DashboardComponent,
    AdminDashboardComponent,
    WildcardErrorPageComponent,
    AdminWrapperComponent,
    UserWrapperComponent,
    UnauthorizedUserComponent,
    ApiKeyComponent,
    AccessRequestComponent,
    UserSearchComponent,
    UserEditComponent,
    UserExistDirective,
    EmailExistDirective,
    LandingComponent,
    ReleaseNotesComponent,
    AutoFocusDirective,
    SysNoteComponent,
    AdminNotificationsComponent,
    PaginationSaveDirective,
    ModelRunDetailsComponent,
    ModelRunStatusComponent,
    CurrentModelsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSidenavModule,
    NoopAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    FlexLayoutModule,
    MatListModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    HttpClientModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    A11yModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
