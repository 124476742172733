import {Injectable} from '@angular/core';
import {User, UserRole, UserStatus} from "../models/user";
import {FMSFNotification} from "../models/notification";
import {Router} from "@angular/router";
import {SidebarService} from "./sidebar.service";
import {HttpService} from "./http.service";

const USER_KEY = 'fmsf-user';
const REDIRECT_KEY = 'fmsf-redirect';

const USER_INIT = {
  user_id: "",
  email: "",
  first_name: "",
  last_name: "",
  org: "",
  account_status_id: UserStatus.NewReg,
  role_id: UserRole.User,
  last_activity: "",
  rob_accept: "",
  userInfo: { user_id: "",
    access_code: "",
    superuser: false,
  }
};

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private _user : User = USER_INIT;
  private _bLoggedIn : boolean = false;
  private _redirectUrl : string = "";
  public editUserAccessRequestPending : FMSFNotification | undefined = undefined;

  // user search screen values
  search_email: any;
  search_user: any;
  search_model: any;
  search_role: any;
  search_status: any;
  search_firstname: any;
  search_lastname: any;
  search_org: any;
  // model run details search values
  mrd_search_user: any;
  mrd_search_model: any;
  mrd_search_status: any;
  mrd_search_createdStart: any;
  mrd_search_createdEnd: any;

  constructor(private router: Router,
              private sidebarService : SidebarService,
              private httpService: HttpService) {}

  get isLoggedIn(): boolean {
    return this._bLoggedIn;
  }

  set isLoggedIn(value: boolean) {
    this._bLoggedIn = value;
  }

  get userRole(): UserRole {
    return this._user.role_id;
  }

  set userRole(value: UserRole) {
    this._user.role_id = value;
  }

  get userStatus(): UserStatus {
    return this._user.account_status_id;
  }

  set userStatus(value: UserStatus) {
    this._user.account_status_id = value;
  }

  initUser() {
    if (this._user.user_id == "") {
      const user = window.localStorage.getItem(USER_KEY);
      if (user) {
        this._user =  JSON.parse(user);
        this._bLoggedIn = true;
      }
    }
  }

  get user(): User {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    this._bLoggedIn = true;
  }

  isValidUser() : boolean {
    let ret = false;
    if (this._user.user_id && this._user.org && this._user.email) {
      ret = true;
    }
    return ret;
  }

  userLogout() {
    this.sidebarService.toggle(false);
    this.sidebarService.enabled = false;

    window.localStorage.removeItem(USER_KEY);
    window.localStorage.removeItem(REDIRECT_KEY);
    this._bLoggedIn = false;
    this._user = USER_INIT;

    this.router.navigate(['/'] );
  }

  doLogout() {
    this.httpService.Post<User>('/logout', {}).subscribe(response => {
      // console.log(response);
      this.userLogout();
    });
  }


  get redirectUrl(): string {
    if (this._redirectUrl == "") {
      const redirectURL = window.localStorage.getItem(REDIRECT_KEY);
      if (redirectURL) {
        this._redirectUrl =  JSON.parse(redirectURL);
      }
    }
    return this._redirectUrl;
  }

  set redirectUrl(value: string) {
    this._redirectUrl = value;
    window.localStorage.removeItem(REDIRECT_KEY);
    window.localStorage.setItem(REDIRECT_KEY, JSON.stringify(value));
  }

  checkRedirect(elseUri? : string) {
    // if we have a normal user trying to access an admin url, clear out the redirect
    // Occurred because an admin clicked an admin link, then on login logged in as a normal user
    if (this.redirectUrl.length > 0 && this.redirectUrl.indexOf("/admin") > -1 && this.user.role_id != UserRole.Admin) {
      this.redirectUrl = "";
    }

    if (this.redirectUrl.length > 0) {
      this.router.navigate([this.redirectUrl] );
      this.redirectUrl = "";
    } else {
      if (elseUri) {
        this.router.navigate([elseUri] );
      }
    }
  }
}
