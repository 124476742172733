import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {HttpService} from "../../../services/http.service";
import {ModelType, OrgList, UtilService} from "../../../services/util.service";
import {SearchUserRet, User, UserPrivs, UserRole, UserStatus} from "../../../models/user";
import {StateService} from "../../../services/state.service";
import {catchError, finalize, Subscription, throwError} from "rxjs";
import {HttpHeaders} from "@angular/common/http";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";

export interface UserTable {
  name: string;
  email: string;
  status: string;
  role: string;
  models: string;
  user_id: string;
  org: string;
}

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  modelTypes : ModelType[] = [];
  modelSub!: Subscription;
  orgList : OrgList[] = [];
  orgSub!: Subscription;

  @ViewChild(MatTable) table!: MatTable<UserTable>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['user_id', 'name', 'email', 'org', 'status', 'role', 'models'];
  dataSource = new MatTableDataSource<UserTable>();

  searching = false;

  constructor(private router : Router,
              private httpService : HttpService,
              public util : UtilService,
              public stateService : StateService) { }

  ngOnInit(): void {
    this.modelSub = this.util.modelTypeSub$.subscribe((mt: ModelType[]) => {
      this.modelTypes = mt;
    });
    this.orgSub = this.util.orgListSub$.subscribe((ol: OrgList[]) => {
      this.orgList = ol;
    });
  }

  ngOnDestroy(): void {
    this.modelSub.unsubscribe();
    this.orgSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onEditUser(row: UserTable) {
    // console.log(row);
    this.router.navigate(['/admin/user-edit/' + row.user_id] );
  }

  onSearch(params: any) {
    this.searching = true;
    Object.keys(params).forEach(key => !params[key] && delete params[key]);
    // console.log(params);
    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers, params };
    return this.httpService.Get<SearchUserRet[]>('/ui_api/admin/searchuser', options).pipe(
      finalize(() => this.searching = false),
      catchError(err => {
        // console.log('Handling error locally.', err);
        return throwError(err);
      })
    ).subscribe((response : SearchUserRet[]) => {
      // console.log(response);
      let data : UserTable[] = [];
      response.map((userRet:SearchUserRet) => {
        let user : UserTable = {
          user_id : userRet.user.user_id,
          name : userRet.user.first_name + ' ' + userRet.user.last_name,
          email : userRet.user.email,
          org : userRet.user.org,
          status : UserStatus[userRet.user.account_status_id],
          role : UserRole[userRet.user.role_id],
          models : userRet.userPrivs.map(m => ' ' + m.id.modelType + '(' + m.concurrentRuns + ')').toString()
        }
        this.appendFullUserPrivs(user, userRet.userPrivs);
        data.push(user);
      });
      this.dataSource.data = data;
    });
  }

  appendFullUserPrivs(user : UserTable, userPrivs : UserPrivs[]) {
    this.modelTypes.forEach(modelType => {
      let userModel = userPrivs.find(userPriv => userPriv.id.modelType === modelType.id);
      // have to use ignore as were adding fields that aren't defined in UserTable
      // Could add them as optional values, but then would need to be updated if new model types are created
      // also if the header order ends up being specified in onExport, might as well add it to UserTable as well
      // @ts-ignore
      user[modelType.id] = userModel?.concurrentRuns || 0
    })
  }

  applyFilter(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onKeypress($event: KeyboardEvent, row: UserTable) {
    // console.log($event.key);
    if($event.key === "Enter") {
      this.onEditUser(row);
    }
  }

  onNewUser() {
    this.router.navigate(['/admin/user-new/']);
  }

  onExport() {
    this.util.exportToExcel(this.dataSource.data, "UserSearchResults");
  }
}
