<div class="fmsf_mat_override">
  <form #fUser="ngForm" (ngSubmit)="onSaveUser(fUser.value)">
    <mat-card>
      <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-card-title role="heading" aria-level="1">User Information for {{user_id}} (<a href="mailto:{{user?.email}}">Email</a>)</mat-card-title>
          <!--          <mat-card-subtitle></mat-card-subtitle>-->
        </div>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <fieldset fxFlex="90%">
          <legend class="cdk-visually-hidden">User Information</legend>
          <div fxLayout="row" fxLayoutGap="16px">
            <div fxLayout="column" fxFlex="50">
              <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput name="email" ngModel type="text" required [ngModel]="user?.email">
              </mat-form-field>
              <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput name="first_name" ngModel type="text" maxlength="32" required [ngModel]="user?.first_name">
              </mat-form-field>
              <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput name="last_name" ngModel type="text" maxlength="32" required [ngModel]="user?.last_name">
              </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="50">
              <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>Organization</mat-label>
                <mat-select name="org" ngModel [ngModel]="user?.org">
                  <mat-option *ngFor="let org of orgList" [value]="org.org_name">
                    {{org.org_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>User Role</mat-label>
                <mat-select name="role_id" ngModel [ngModel]="role">
                  <mat-option *ngFor="let key of util.roleKeys" [value]="key">
                    {{util.role[$any(key)]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>User Status</mat-label>
                <mat-select name="account_status_id" ngModel [ngModel]="status">
                  <mat-option *ngFor="let key of util.statusKeys" [value]="key">
                    {{util.status[$any(key)]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>Super User</mat-label>
                <mat-select name="superuser" ngModel [ngModel]="superuser">
                  <mat-option *ngFor="let key of util.superUserKeys" [value]="key">
                    {{util.superuser[$any(key)]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="16px">
            <mat-form-field floatLabel="always" appearance="standard" class="mat-form-field-readonly activity_date_width">
              <mat-label>Last Activity</mat-label>
              <input matInput name="last_activity" ngModel type="text" [ngModel]="user?.last_activity" [readonly]="true">
            </mat-form-field>
            <mat-form-field floatLabel="always" appearance="standard" class="mat-form-field-readonly activity_date_width">
              <mat-label>ROB Accept</mat-label>
              <input matInput name="rob_accept" ngModel type="text" [ngModel]="user?.rob_accept" [readonly]="true">
            </mat-form-field>
          </div>
        </fieldset>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="center">
        <div fxFlex="50">
          <div fxFlex=""></div>
          <button mat-raised-button type="submit" color="primary" [disabled]="!fUser.valid || !fUser.dirty || savingUser">Save</button>
        </div>
        <div fxFlex="50">
          <div fxFlex=""></div>
          <button mat-raised-button type="button" color="warn" (click)="onDeleteUser()">Delete User</button>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>

  <form #fModels="ngForm" (ngSubmit)="onSaveModels(fModels.value)">
    <mat-card>
      <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-card-title role="heading" aria-level="1">Models</mat-card-title>
          <!--          <mat-card-subtitle></mat-card-subtitle>-->
        </div>
      </mat-card-header>
      <mat-card-content fxLayout="column" fxLayoutAlign="start center">
        <fieldset fxFlex="90%" fxLayout="row" fxLayoutGap="16px">
          <legend class="cdk-visually-hidden">Models</legend>
          <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="center">
            <div *ngFor="let x of accessRequest; let i = index">
              <div class="model-layout">
                <span>{{modelTypes[i].id}} - {{modelTypes[i].shortName}} - {{modelTypes[i].commonName}}</span>
                <div fxFlex=""></div>
                <mat-form-field floatLabel="always" appearance="outline" class="run-width" fxFlexAlign="end">
                  <mat-label>Runs</mat-label>
                  <input matInput type="number" name="{{x.id.modelType}}" [(ngModel)]="x.concurrentRuns" min="0">
                </mat-form-field>
              </div>
            </div>
          </div>
        </fieldset>
        <div *ngIf="accessRequestPending?.notificationType === FMSFNotificationType.MODEL_ACCESS">Pending: {{accessRequestPending?.details}}</div>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="center">
        <button mat-raised-button type="submit" color="primary" [disabled]="!fModels.valid || !fModels.dirty || savingModels">Save</button>
<!--        <button mat-raised-button type="reset" color="warn">Reset?</button>-->
      </mat-card-actions>
    </mat-card>
  </form>

</div>
