import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDrawer} from "@angular/material/sidenav";
import {SidebarService} from "./services/sidebar.service";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {catchError, EMPTY, filter} from "rxjs";
import {map} from "rxjs/operators";
import {UtilService} from "./services/util.service";
import {HttpHeaders} from "@angular/common/http";
import {User} from "./models/user";
import {HttpService} from "./services/http.service";
import {StateService} from "./services/state.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'FMSF';
  firstLoad = true;

  @ViewChild('drawer') public drawer!: MatDrawer;

  constructor(private router : Router,
              private activatedRoute: ActivatedRoute,
              private titleService : Title,
              public sideBarService:SidebarService,
              private util : UtilService,
              private httpService : HttpService,
              private stateService : StateService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data &&    child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe( (data: any) => {
      if (data) {
        if (!util.skipLinkPath || this.router.url === "/" || util.skipLinkPath.indexOf(this.router.url) == -1) {
          util.skipLinkPath = `/fmsf_ui${this.router.url}#mainContent`;
        }
        // NVDA is double announcing on initial page load...
        // also announces after the autofocus controls...
        this.titleService.setTitle(data + ' | FMSF');
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          util.liveAnnounce(data + ' | FMSF', 'assertive');
        }
      }
      this.sideBarService.autosize = true;
      setTimeout(() => this.sideBarService.autosize = false, 1);
    });
  }

  ngOnInit() {
    this.checkLoginValid();
  }

  ngAfterViewInit(): void {
    this.sideBarService.setDrawer(this.drawer);

    if (navigator.clipboard) {
      this.util.haveClipboard = true;
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  checkLoginValid() {
    this.checkSessionForUser();

    const headers = new HttpHeaders({'Content-Type':  'application/json'});
    const options = { ...headers};
    return  this.httpService.Get<User>('/ui_api/whoami', options).pipe(
      catchError(err => {
        // console.log('Handling error locally.', err);
        return EMPTY;
      })
    ).subscribe(response => {
      // console.log(response);
      if (!!response) {
        if (response.user_id === 'anon') {
          this.stateService.isLoggedIn = true;
          this.stateService.user.email = response.email;
        } else {
          this.stateService.user = response;
        }
        this.util.init();
        this.stateService.checkRedirect();
      }
    });
  }

  checkSessionForUser() {
    // on a F5/reload, page will load faster than http call
    // check for user in session and use until call comes back.
    this.stateService.initUser();
  }

}
