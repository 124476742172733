import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {SidebarService} from "../../services/sidebar.service";

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {

  constructor(public sideBarService: SidebarService,
              private router: Router) { }

  ngOnInit(): void {
  }

  onHome() {
    this.router.navigate(['/'] );
  }
}
