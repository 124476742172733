import {Directive, Input, OnInit} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";


@Directive({
  selector: '[appPaginationSave]'
})
export class PaginationSaveDirective implements OnInit {
  private element: MatPaginator;
  @Input() lsKey!: string;  // required
  @Input() defaultSize: number = 25;

  get pageSize() {
    const ps = localStorage.getItem(this.lsKey);
    if (ps) {
      return JSON.parse(ps);
    } else {
      return this.defaultSize;
    }
  }

  set pageSize(size: number) {
    localStorage.setItem(this.lsKey, JSON.stringify(size));
  }

  constructor(private el: MatPaginator) {
    this.element = el;
  }

  ngOnInit(): void {
    this.element.pageSize = this.pageSize;

    this.element.page.subscribe((page: PageEvent) => {
      this.pageSize = page.pageSize;
    });
  }
}
