<div fxLayoutAlign="space-evenly">
  <mat-card class="non-authorized-user">
    <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
      <mat-card-title role="heading" aria-level="1">User is not Authorized</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-card-content>
<!--        for now not logged in redirecting to login page, later can add actual route to FamAuth-->
        <span *ngIf="type ==='notloggedin'">Please login via <a href="javascript:void(0)" (click)="goLanding()">FamAuth</a> to login.</span>
        <span *ngIf="type ==='newreg'">Thank you for registering, please wait for an admin to authorize your account.</span>
        <span *ngIf="type ==='denied'">Your account is currently disabled. If you have not received an email notice, please contact support for further information.</span>
        <span *ngIf="type ==='disabled'">Your account is currently disabled due to inactivity. Please logout and back in.</span>
        <span *ngIf="type ==='admin'">User not allowed access to admin only area. Please login as an admin or you can return to your <a href="javascript:void(0)" (click)="goDashboard()">Dashboard</a>.</span>
      </mat-card-content>
    </mat-card-content>
  </mat-card>
</div>

