<div fxLayoutAlign="space-evenly">
<!--  <form #f="ngForm" (ngSubmit)="onLogin(f.value)">-->
    <mat-card>
      <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-card-title role="heading" aria-level="1">FAMAuth Login Failure</mat-card-title>
        </div>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayoutAlign="center">
        <div fxFlex="75%" fxLayout="column">
          <p>There was an error during FAMAuth authentication</p>
<!--          <mat-form-field floatLabel="always" appearance="outline">-->
<!--            <mat-label>Username</mat-label>-->
<!--            <input matInput name="username" ngModel type="email" required>-->
<!--          </mat-form-field>-->
<!--          <mat-form-field floatLabel="always" appearance="outline">-->
<!--            <mat-label>Password</mat-label>-->
<!--            <input matInput name="password" ngModel type="password" required>-->
<!--          </mat-form-field>-->
        </div>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="center">
<!--        <button mat-raised-button type="submit" color="primary" [disabled]="f.invalid">Login</button>-->
        <button mat-raised-button color="primary" (click)="onLanding()">Return To Landing</button>
      </mat-card-actions>
    </mat-card>
<!--  </form>-->
</div>

<!--<mat-divider></mat-divider>-->
<!--<mat-card>-->
<!--  <br>-->
<!--  <br>-->
<!--  <br>-->
<!--  <br>-->
<!--  <br>-->
<!--  <br>-->
<!--  <span>Test</span>-->
<!--  <br>-->
<!--  <button mat-raised-button color="primary" (click)="onRegister()">Register</button>-->
<!--  <button mat-raised-button color="accent" (click)="onUserDashboard()">User Dashboard</button>-->
<!--  <button mat-raised-button color="accent" (click)="onApiKey()">Api</button>-->
<!--  <button mat-raised-button color="accent" (click)="onAccessRequest()">Access</button>-->
<!--  <button mat-raised-button color="warn" (click)="onAdminDashboard()">Admin Dashboard</button>-->
<!--  <button mat-raised-button color="warn" (click)="onUserSearch()">Search</button>-->
<!--  <button mat-raised-button color="warn" (click)="onUserEdit()">Edit</button>-->
<!--  <br>-->
<!--  <mat-divider></mat-divider>-->
<!--  <form #f2="ngForm" (ngSubmit)="setVars(f2.value)">-->
<!--    <table>-->
<!--      <tr>-->
<!--        <td>-->
<!--          <mat-form-field floatLabel="always" appearance="outline" class="login-test-number">-->
<!--            <mat-label>Logged In</mat-label>-->
<!--            <input matInput name="logged_in" type="number" ngModel min="0" max="1">-->
<!--          </mat-form-field>-->
<!--        </td>-->
<!--        <td>-->
<!--          <mat-form-field floatLabel="always" appearance="outline" class="login-test-number">-->
<!--            <mat-label>Status</mat-label>-->
<!--            <input matInput name="status" type="number" ngModel min="0" max="2">-->
<!--          </mat-form-field>-->
<!--        </td>-->
<!--        <td>-->
<!--          <mat-form-field floatLabel="always" appearance="outline" class="login-test-number">-->
<!--            <mat-label>Role</mat-label>-->
<!--            <input matInput name="role" type="number" ngModel min="0" max="1">-->
<!--          </mat-form-field>-->
<!--        </td>-->
<!--        <td>-->
<!--          <mat-form-field floatLabel="always" appearance="outline">-->
<!--            <mat-label>User</mat-label>-->
<!--            <input matInput name="user" type="text" ngModel maxlength="32">-->
<!--          </mat-form-field>-->
<!--        </td>-->
<!--        <td>-->
<!--          <mat-form-field floatLabel="always" appearance="outline" >-->
<!--            <mat-label>APIKey</mat-label>-->
<!--            <input matInput name="key" type="text" ngModel>-->
<!--          </mat-form-field>-->
<!--        </td>-->
<!--      </tr>-->
<!--    </table>-->
<!--    <button mat-raised-button type="submit" color="primary">Set Vars</button>-->
<!--  </form>-->
<!--</mat-card>-->

